import { model } from '@zentrains/api';
import { type SitzplatzGruppe, SitzplatzGruppeTyp } from '@/model/sonderSitzplatz';

export function mapExternalSitzplatzGruppe(externalSitzplatzGruppe: model.SitzplatzGruppe): SitzplatzGruppe | null {
  const typ = mapExternalSitzplatzGruppeTyp(externalSitzplatzGruppe.typ);
  if (typ === null) {
    return null;
  }
  return {
    typ,
    sitzPlaetze: externalSitzplatzGruppe.sitzPlaetze,
  };
}

export function mapExternalSitzplatzGruppeTyp(externalTyp: model.SitzplatzGruppeTyp): SitzplatzGruppeTyp | null {
  switch (externalTyp) {
    case model.SitzplatzGruppeTyp.BAHNBONUS:
      return SitzplatzGruppeTyp.BAHNBONUS;
    case model.SitzplatzGruppeTyp.EXPRESSRESERVIERUNG:
      return SitzplatzGruppeTyp.EXPRESSRESERVIERUNG;
    case model.SitzplatzGruppeTyp.MOBILITAETSEINGESCHRAENKT:
      return SitzplatzGruppeTyp.MOBILITAETSEINGESCHRAENKT;
    case model.SitzplatzGruppeTyp.ROLLSTUHL:
      return SitzplatzGruppeTyp.ROLLSTUHL;
    case model.SitzplatzGruppeTyp.UNRECOGNIZED:
    default:
      return null;
  }
}
