<template>
  <ion-item
    v-if="isServiceWorkerActive"
    :detail="false"
    class="hydrated"
    lines="none"
    @click="serviceWorker.checkForUpdates"
  >
    <ion-icon slot="start" :icon="refreshOutline" />
    <ion-label>Nach Updates suchen</ion-label>
  </ion-item>
</template>

<script setup lang="ts">
import { IonIcon, IonItem, IonLabel } from '@ionic/vue';
import { refreshOutline } from 'ionicons/icons';
import { useServiceWorkerStore } from '@/stores/serviceWorker';
import { storeToRefs } from 'pinia';

const serviceWorker = useServiceWorkerStore();
const { isServiceWorkerActive } = storeToRefs(serviceWorker);
</script>

<style scoped lang="scss"></style>
