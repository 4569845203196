import { type FahrtDto } from '@/model/dto/FahrtDto';
import { Fahrt } from '@/model/fahrt';

export function mapDtoToFahrt(fahrt: FahrtDto): Fahrt {
  return new Fahrt(
    fahrt.betreiber,
    fahrt.gattung,
    fahrt.fahrtNummer,
    fahrt.linie,
    fahrt.zielHalt,
    fahrt.isZusatzfahrt,
    fahrt.allFahrtMerkmal,
    fahrt.startZeit !== null ? new Date(fahrt.startZeit) : null,
  );
}
