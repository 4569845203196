import { model } from '@zentrains/api';
import { Halt } from '@/model/halt';
import { mapExternalFahrtereignis } from '@/mapper/from-api/fahrtereignis';
import { mapExternalAuslastung } from '@/mapper/from-api/auslastung';
import { mapExternalMeldung } from '@/mapper/from-api/meldung';
import { mapExternalHaltestelle } from '@/mapper/from-api/haltestelle';

export function mapExternalHalt(externalHalt: model.Halt): Halt {
  const haltestelle = externalHalt.haltestelle;
  if (haltestelle === undefined) {
    throw 'Haltestelle of Halt is undefined, which is illegal';
  }

  return new Halt(
    mapExternalFahrtereignis(externalHalt.abfahrt || null),
    mapExternalFahrtereignis(externalHalt.ankunft || null),
    mapExternalHaltestelle(haltestelle),
    mapExternalAuslastung(externalHalt.auslastung || null),
    externalHalt.allMeldung.map(mapExternalMeldung),
  );
}
