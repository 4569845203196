import { computed, type Ref } from 'vue';
import { formatDurationInHours } from '@/composables/helper';
import { startOfMinute } from 'date-fns';
import { convertIntervalToDuration } from '@/model/zeit';
import { VerzoegerungOhnePrognose } from '@/model/verzoegerung';
import type { LastAbschnittPickup } from '@/stores/pickupAssistance';

export default function usePickupAssistanceTexts(
  currentTime: Ref<number>,
  nextPickup: Ref<LastAbschnittPickup | null>,
) {
  const command = computed(() => {
    if (nextPickup.value !== null) {
      return `${nextPickup.value.pickup.name} kommt in`;
    }
    return 'Gleich geht es los...';
  });
  const station = computed(() => {
    if (nextPickup.value !== null) {
      let text = `in ${nextPickup.value.lastLeg.ankunft.haltestelle.name} an.`;
      const platform =
        nextPickup.value.lastLeg.ankunft.fahrtereignis.progGleis ||
        nextPickup.value.lastLeg.ankunft.fahrtereignis.sollGleis;
      if (platform !== '') {
        text = `auf Gl. ${platform} ${text}`;
      }
      return text;
    }
    return 'Verbindungsdaten werden geladen.';
  });
  const time = computed(() => {
    if (nextPickup.value !== null) {
      const curTime = startOfMinute(currentTime.value);
      const arrival = nextPickup.value.lastLeg.ankunft.fahrtereignis.getZeit();
      const diff = convertIntervalToDuration(curTime, arrival);
      return formatDurationInHours(diff);
    }
    return '';
  });
  const rawDelay = computed(() => {
    if (nextPickup.value !== null) {
      return nextPickup.value.lastLeg.ankunft.fahrtereignis.getVerzoegerung();
    }
    return new VerzoegerungOhnePrognose();
  });
  const hasDelay = computed(() => {
    return rawDelay.value.hasPrognose;
  });
  const isDelayOver5Minutes = computed(() => !rawDelay.value.isUnderFiveMinutes());
  const delay = computed(() => rawDelay.value.format());

  return {
    command,
    time,
    station,
    delay,
    hasDelay,
    isDelayOver5Minutes,
  };
}
