import { defineStore } from 'pinia';
import { useBackendStore } from '@/stores/backend';
import { mapExternalOmniItem } from '@/mapper/from-api/omniSuche';
import type { OmniItem } from '@/model/omniSuche';

export interface OmniSearchState {
  query: string;
  loading: boolean;
  searchFieldInFocus: boolean;
  allItem: OmniItem[];
}

export const useOmniSearchStore = defineStore('omniSearch', {
  state: (): OmniSearchState => ({
    query: '',
    loading: false,
    searchFieldInFocus: false,
    allItem: [],
  }),
  actions: {
    async setQueryAndFetchStops(query: string) {
      if (this.searchFieldInFocus) {
        this.query = query;
        this.loading = true;
        const backendStore = useBackendStore();

        try {
          const response = await backendStore.client.omniSearch({
            query: this.query,
          });
          this.allItem = response.allItem.map(mapExternalOmniItem);
        } finally {
          this.loading = false;
        }
      }
    },
  },
});
