import { type Duration } from 'date-fns';

export function filterNotNull<T>(array: (T | null)[]): T[] {
  return <T[]>array.filter((e) => e !== null);
}

export function mapExternalDauer(dauer: number): Duration {
  return {
    days: Math.floor(dauer / 86400),
    hours: Math.floor((dauer % 86400) / 3600),
    minutes: (dauer % 3600) / 60,
  };
}
