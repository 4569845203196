import { type Messaging, onMessage } from 'firebase/messaging';
import { startSpan } from '@sentry/browser';
import { useNotificationsStore } from '@/stores/notifications';
import { showNotificationIfNecessary } from '@/notifications/showNotification';

export async function setupMessageReception(
  messaging: Messaging,
  serviceWorkerRegistration: ServiceWorkerRegistration,
) {
  onMessage(messaging, async (payload) => {
    await startSpan(
      {
        name: 'push-notification/foreground',
      },
      async (span) => {
        const result = await showNotificationIfNecessary(serviceWorkerRegistration, payload);
        span.setAttribute('notificationSent', result);
      },
    );
  });
  const notificationsStore = useNotificationsStore();
  await notificationsStore.sendAllDataToNotificationServer();
}
