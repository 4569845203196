import { VerzoegerungMitPrognose, VerzoegerungOhnePrognose } from '@/model/verzoegerung';
import { type Interval, intervalToDuration } from 'date-fns';

export function convertIntervalToDuration(start: Date, end: Date) {
  const verzoegerung: Interval = {
    start: start,
    end: end,
  };

  return intervalToDuration(verzoegerung);
}

export class Zeit {
  readonly sollZeit: Date;
  readonly progZeit: Date | null;

  constructor(sollZeit: Date, progZeit: Date | null) {
    this.sollZeit = sollZeit;
    this.progZeit = progZeit;
  }

  hasProgZeit(): boolean {
    return this.progZeit !== null;
  }

  getZeit(): Date {
    if (this.progZeit !== null) {
      return this.progZeit;
    }
    return this.sollZeit;
  }

  getVerzoegerung(): VerzoegerungOhnePrognose | VerzoegerungMitPrognose {
    if (this.progZeit !== null) {
      const duration = convertIntervalToDuration(this.sollZeit, this.progZeit);
      return new VerzoegerungMitPrognose(duration);
    }
    return new VerzoegerungOhnePrognose();
  }
}
