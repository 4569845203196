import { defineStore } from 'pinia';
import VERSION from '@/env/version';

export interface VersionState {
  lastVersion: string;
}

export const useVersionStore = defineStore('version', {
  state: (): VersionState => ({
    lastVersion: VERSION,
  }),
});
