import { App, type URLOpenListenerEvent } from '@capacitor/app';
import { useRouter } from 'vue-router';
import { Capacitor } from '@capacitor/core';

export default function useDeepLinks() {
  const router = useRouter();

  App.addListener('appUrlOpen', function (event: URLOpenListenerEvent) {
    const url = new URL(event.url);

    // We only push to the route if there is a slug present
    if (isRelevantUrl(url)) {
      // noinspection JSIgnoredPromiseFromCall
      router.push({
        path: url.pathname,
        query: Object.fromEntries(url.searchParams),
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  }).then((r) => {
    /*noop*/
  });
}

function isRelevantUrl(url: URL): boolean {
  switch (Capacitor.getPlatform()) {
    case 'web':
      return url.hostname === 'zentrains.de' && url.pathname !== '/';
    case 'android':
      return url.origin === 'cloud.zenti.zentrains://' && url.pathname !== '/';
  }
  return false;
}
