import { createChannel, createClient } from 'nice-grpc-web';
import { DEV_ENDPOINT } from '@/api/common';
import { api, notification } from '@zentrains/api';

const devChannel = createChannel(DEV_ENDPOINT);
export const devApi: api.ZenTrainsClient = createClient(api.ZenTrainsDefinition, devChannel);
export const devNotificationApi: notification.NotificationClient = createClient(
  notification.NotificationDefinition,
  devChannel,
);
