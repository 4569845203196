import { defineStore } from 'pinia';
import { type PickupsByDate } from '@/types';
import { convertPickupsToPickupsByDate } from '@/composables/helper';
import { useSettingsStore } from '@/stores/settings';
import { useNotificationsStore } from '@/stores/notifications';
import { captureException } from '@sentry/vue';
import { type Abschnitt, AbschnittTyp } from '@/model/abschnitt';
import { type Reise } from '@/model/reise';
import { mapDtoToReise } from '@/mapper/from-store-dto/reise';
import { mapReiseToDto } from '@/mapper/to-store-dto/reise';
import { useBackendStore } from '@/stores/backend';
import { notification } from '@zentrains/api';
import { mapToExternalIdentifikation } from '@/mapper/to-api/identifikation';
import { Identifikation } from '@/model/identifikation';
import type { ReiseIdToReiseDto } from '@/stores/reiseBegleitung';

export interface PickupAssistanceState {
  allPickup: Pickup[];
  reiseIdToReiseDto: ReiseIdToReiseDto;
  updating: boolean;
}

export interface Pickup {
  name: string;
  reiseId: Identifikation;
  coach: string;
}

export interface LastAbschnittPickup {
  pickup: Pickup;
  lastLeg: Abschnitt;
}

function byDate(a: LastAbschnittPickup, b: LastAbschnittPickup) {
  return a.lastLeg.ankunft.fahrtereignis.getZeit().getTime() - b.lastLeg.ankunft.fahrtereignis.getZeit().getTime();
}

export const usePickupAssistanceStore = defineStore('pickupAssistance', {
  state: (): PickupAssistanceState => ({
    allPickup: [],
    reiseIdToReiseDto: {},
    updating: false,
  }),
  persist: true,
  getters: {
    allReise(): Reise[] {
      return Object.values(this.reiseIdToReiseDto).map(mapDtoToReise);
    },
    reiseIdToReiseMap(): Map<string, Reise> {
      return new Map<string, Reise>(this.allReise.map((r) => [r.id.toString(), r]));
    },
    lastLegsMap(): Map<string, LastAbschnittPickup> {
      return new Map<string, LastAbschnittPickup>(
        this.allPickup
          .filter((p) => this.reiseIdToReiseMap.has(p.reiseId.toString()))
          .map((p) => [
            p.reiseId.toString(),
            {
              pickup: p,
              lastLeg: this.reiseIdToReiseMap
                .get(p.reiseId.toString())!
                .allAbschnitt.filter((leg) => leg.typ === AbschnittTyp.Fahrt)
                .reverse()[0],
            },
          ]),
      );
    },
    lastLegs(): LastAbschnittPickup[] {
      return [...this.lastLegsMap.values()].sort(byDate);
    },
    pickupsByDate(): PickupsByDate {
      return this.lastLegs.reduce(convertPickupsToPickupsByDate, {} as PickupsByDate);
    },
  },
  actions: {
    getPickup(reiseId: string): LastAbschnittPickup | null {
      return this.lastLegsMap.get(reiseId) || null;
    },
    deleteAllPickups() {
      this.reiseIdToReiseDto = {};
      this.allPickup = [];
    },
    async sendPickupsToNotificationServer() {
      const settings = useSettingsStore();
      const notifications = useNotificationsStore();
      if (settings.pushNotificationsActive && notifications.pushToken !== '') {
        const request: notification.SaveAllPickupRequest = {
          deviceKey: notifications.pushToken,
          allPickupItem: this.allPickup.map((p): notification.PickupItem => {
            let startZeit = this.reiseIdToReiseMap.get(p.reiseId.toString())?.abfahrt.sollZeit.valueOf();
            if (!startZeit) {
              startZeit = Date.now();
            }
            return {
              identifikation: mapToExternalIdentifikation(p.reiseId),
              startZeit: startZeit,
              name: p.name,
              sitzPlatz: {
                wagen: p.coach,
                sitzplatz: '',
              },
            };
          }),
        };
        const backend = useBackendStore();
        await backend.notificationClient.saveAllPickup(request);
      }
    },
    async deletePickup(reiseId: Identifikation) {
      delete this.reiseIdToReiseDto[reiseId.toString()];
      const index = this.allPickup.findIndex((p) => p.reiseId.toString() === reiseId.toString());
      if (index !== -1) {
        this.allPickup.splice(index, 1);
      }
      await this.sendPickupsToNotificationServer();
    },
    async refreshPickup(reset: boolean, ...pickups: Pickup[]) {
      try {
        this.updating = true;
        const backend = useBackendStore();
        const allReise = await backend.refreshReise(pickups.map((p) => p.reiseId));
        if (reset && allReise.length === pickups.length) {
          this.allPickup = [];
          this.reiseIdToReiseDto = allReise.reduce((obj: ReiseIdToReiseDto, reise: Reise) => {
            const pickup = pickups.find((p) => p.reiseId.toString() === reise.id.toString());
            this.allPickup.push({
              name: pickup?.name || '',
              coach: pickup?.coach || '',
              reiseId: reise.id,
            });
            obj[reise.id.toString()] = mapReiseToDto(reise);
            return obj;
          }, {} as ReiseIdToReiseDto);
        } else {
          allReise.forEach((reise) => {
            this.reiseIdToReiseDto[reise.id.toString()] = mapReiseToDto(reise);
          });
        }
        await this.sendPickupsToNotificationServer();
      } catch (e) {
        captureException(e);
      } finally {
        this.updating = false;
      }
    },
    async savePickup(pickup: Pickup) {
      this.allPickup.push(pickup);
      await this.refreshPickup(false, pickup);
    },
    async refreshPickups() {
      if (this.allPickup.length > 0) {
        await this.refreshPickup(true, ...this.allPickup);
      }
    },
  },
});
