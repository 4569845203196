import { model } from '@zentrains/api';
import { mapToExternalQuelle } from '@/mapper/to-api/quelle';
import { Identifikation } from '@/model/identifikation';

export function mapToExternalIdentifikation(identifikation: Identifikation): model.Identifikation {
  return {
    id: identifikation.id,
    quelle: mapToExternalQuelle(identifikation.quelle),
  };
}
