import { type OmniBetriebsstelle, type OmniHaltestelle, type OmniItem, OmniTyp } from '@/model/omniSuche';
import { model } from '@zentrains/api';

export function mapExternalOmniItem(omniItem: model.OmniItem): OmniItem {
  if (omniItem.haltestelle !== undefined) {
    return mapExternalOmniHaltestelle(omniItem.haltestelle);
  } else if (omniItem.betriebsstelle !== undefined) {
    return mapExternalOmniBetriebsstelle(omniItem.betriebsstelle);
  }
  throw 'Unexpected OmniItem type';
}

function mapExternalOmniHaltestelle(omniHaltestelle: model.OmniHaltestelle): OmniHaltestelle {
  return {
    typ: OmniTyp.HALTESTELLE,
    name: omniHaltestelle.name,
    evaNummer: omniHaltestelle.evaNummer,
    rl100: omniHaltestelle.rl100 || null,
  };
}

function mapExternalOmniBetriebsstelle(omniBetriebsstelle: model.OmniBetriebsstelle): OmniBetriebsstelle {
  return {
    typ: OmniTyp.BETRIEBSSTELLE,
    name: omniBetriebsstelle.name,
    rl100: omniBetriebsstelle.rl100,
    betriebsstelleTyp: omniBetriebsstelle.typ,
  };
}
