import type { MessagePayload } from 'firebase/messaging';

export async function showNotificationIfNecessary(
  serviceWorkerRegistration: ServiceWorkerRegistration,
  payload: MessagePayload,
): Promise<boolean> {
  if (payload.data) {
    const title = payload.data.title;
    const body = payload.data.body;
    const details = payload.data.details;
    if (title !== undefined && body !== undefined) {
      await serviceWorkerRegistration.showNotification(title, {
        body:
          details !== ''
            ? `${body}
${details}`
            : body,
        icon: '/img/icons/android-chrome-192x192.png',
        badge: '/img/icons/android-chrome-192x192.png',
      });
      return true;
    }
  }
  return false;
}
