export class Fahrt {
  readonly betreiber: Betreiber;
  readonly gattung: string;
  readonly fahrtNummer: number;
  readonly linie: string;
  readonly isZusatzfahrt: boolean;
  readonly allFahrtMerkmal: FahrtMerkmal[];
  readonly zielHalt: string;
  readonly startZeit: Date | null;

  constructor(
    betreiber: Betreiber,
    gattung: string,
    fahrtNummer: number,
    linie: string,
    zielHalt: string,
    isZusatzfahrt: boolean,
    allFahrtMerkmal: FahrtMerkmal[],
    startZeit: Date | null,
  ) {
    this.betreiber = betreiber;
    this.gattung = gattung;
    this.fahrtNummer = fahrtNummer;
    this.linie = linie;
    this.isZusatzfahrt = isZusatzfahrt;
    this.allFahrtMerkmal = allFahrtMerkmal;
    this.zielHalt = zielHalt;
    this.startZeit = startZeit;
  }

  getName(): string {
    return this.isLinieSichtbar() ? `${this.gattung} ${this.linie}` : `${this.gattung} ${this.fahrtNummer}`;
  }

  hasLinie(): boolean {
    return this.linie !== '';
  }

  isLinieSichtbar(): boolean {
    return (
      this.hasLinie() && this.linie !== this.fahrtNummer.toString(10) && gattungOhneLinie.indexOf(this.gattung) === -1
    );
  }
}

export interface Betreiber {
  betreiberId: string;
  name: string;
}

export enum FahrtMerkmal {
  FahrradmitnahmeReservierungspflichtig,
  FahrradmitnahmeMoeglich,
  MitNetzcardFahrbar,
  ReservierungsPflicht,
}

const gattungOhneLinie: string[] = ['ICE', 'IC', 'ECE', 'EC', 'RJX', 'RJ', 'NJ', 'TGV'];
