import { notification } from '@zentrains/api';
import type { Reise } from '@/model/reise';
import { mapToExternalIdentifikation } from '@/mapper/to-api/identifikation';

export function mapToExternalReiseItem(reise: Reise): notification.ReiseItem {
  return {
    identifikation: mapToExternalIdentifikation(reise.id),
    startZeit: reise.abfahrt.sollZeit.valueOf(),
    abschnittKeyToReservierungMap: {},
  };
}
