import { createChannel, createClient } from 'nice-grpc-web';
import { PRD_ENDPOINT } from '@/api/common';
import { api, notification } from '@zentrains/api';

const prdChannel = createChannel(PRD_ENDPOINT);
export const prdApi: api.ZenTrainsClient = createClient(api.ZenTrainsDefinition, prdChannel);
export const prdNotificationApi: notification.NotificationClient = createClient(
  notification.NotificationDefinition,
  prdChannel,
);
