import { type FirebaseOptions } from 'firebase/app';

export const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyC4fpIJRV23CQ-yclg2XYC9gfDxCrSQIrg',
  authDomain: 'zentrains.firebaseapp.com',
  projectId: 'zentrains',
  storageBucket: 'zentrains.appspot.com',
  messagingSenderId: '828936971912',
  appId: '1:828936971912:web:ff94232a2c1ad2c9bae13d',
};
