import { isDeviceOffline } from '@/stores/common/device-offline';
import { UPDATE_CHECK_PERIOD } from '@/stores/service-worker/constants';

export async function doUpdateCheck(swRegistration: ServiceWorkerRegistration, swUrl: string) {
  if (isDeviceOffline()) return;

  const resp = await fetch(swUrl, {
    cache: 'no-store',
    headers: {
      cache: 'no-store',
      'cache-control': 'no-cache',
    },
  });

  if (resp?.status === 200) await swRegistration.update();
}

/**
 * This function will register a periodic sync check every hour, you can modify the interval as needed.
 */
export function registerPeriodicSync(swRegistration: ServiceWorkerRegistration, swUrl: string) {
  if (UPDATE_CHECK_PERIOD <= 0) return;

  setInterval(async () => {
    await doUpdateCheck(swRegistration, swUrl);
  }, UPDATE_CHECK_PERIOD);
}
