import { type Duration } from 'date-fns';
import { Fahrt } from '@/model/fahrt';
import { Halt } from '@/model/halt';
import { type Wagenreihung } from '@/model/wagenreihung';
import { Fahrtereignis } from '@/model/fahrtereignis';
import { type Meldung } from '@/model/meldung';
import { type Auslastung } from '@/model/auslastung';
import type { Haltestelle } from '@/model/haltestelle';

export interface Abschnitt {
  readonly fahrtId: string;
  readonly typ: AbschnittTyp;

  readonly abfahrt: AbschnittFahrtereignis;
  readonly ankunft: AbschnittFahrtereignis;
  readonly dauer: Duration;
  readonly umstiegDauer: Duration;
  readonly auslastung: Auslastung | null;

  readonly fahrt: Fahrt;

  readonly allHalt: Halt[];
  readonly allFluegel: AbschnittFluegel[];
  readonly allMeldung: Meldung[];

  readonly isAusfall: boolean;
  readonly isErreichbar: boolean;

  readonly umstiegBewertung: UmstiegBewertung | null;
}

export interface AbschnittFluegel {
  readonly fahrtId: string;

  readonly fahrt: Fahrt;

  readonly allMeldung: Meldung[];
}

export interface AbschnittFahrtereignis {
  readonly fahrtereignis: Fahrtereignis;
  readonly haltestelle: Haltestelle;
  readonly isUnbestimmtVerspaetet: boolean;
  readonly wagenreihung: Wagenreihung | null;
}

export enum AbschnittTyp {
  Fahrt,
  Fussweg,
  Transfer,
}

export enum UmstiegBewertung {
  Unbekannt,
  Gesichert,
  Wahrscheinlich,
  KeineInformation,
  Gefaehrdet,
  Abgelehnt,
}
