import { model } from '@zentrains/api';
import { Quelle } from '@/model/identifikation';

export function mapExternalQuelle(quelle: model.Quelle): Quelle {
  switch (quelle) {
    case model.Quelle.QUELLE_DB_BUSINESS_NAVIGATOR:
      return Quelle.DB_BUSINESS_NAVIGATOR;
    case model.Quelle.QUELLE_DB_NAVIGATOR:
      return Quelle.DB_NAVIGATOR;
    case model.Quelle.QUELLE_OEBB:
      return Quelle.OEBB;
    case model.Quelle.QUELLE_SBB:
      return Quelle.SBB;
    case model.Quelle.QUELLE_IRIS:
      return Quelle.IRIS;
    case model.Quelle.QUELLE_MUY_VLAK:
      return Quelle.MUY_VLAK;
    case model.Quelle.QUELLE_NS:
      return Quelle.NS;
    case model.Quelle.QUELLE_PKP:
      return Quelle.PKP;
    case model.Quelle.QUELLE_SNCF_CONNECT:
      return Quelle.SNCF_CONNECT;
    case model.Quelle.UNRECOGNIZED:
      return Quelle.UNBEKANNT;
  }
}
