<template>
  <ion-footer v-if="nextPickupIndex !== -1" class="ion-no-border">
    <ion-toolbar class="journey-overview">
      <div class="ion-text-center">
        <div class="first-line">
          {{ command }}
          <span class="time">
            {{ time }}h
            <span v-if="hasDelay" class="delay" :class="{ over5: isDelayOver5Minutes }">{{ delay }}</span>
          </span>
          {{ station }}
        </div>
        <div class="second-line"></div>
      </div>
    </ion-toolbar>
  </ion-footer>
</template>

<script lang="ts">
import { IonFooter, IonToolbar } from '@ionic/vue';
import usePickupAssistanceCoreData from '@/composables/pickup-assistance/usePickupAssistanceCoreData';
import usePickupAssistanceTexts from '@/composables/pickup-assistance/usePickupAssistanceTexts';

export default {
  name: 'PickupOverlay',
  components: {
    IonFooter,
    IonToolbar,
  },
  setup() {
    const pickupAssistanceCoreData = usePickupAssistanceCoreData();

    const assistanceTexts = usePickupAssistanceTexts(
      pickupAssistanceCoreData.currentTime,
      pickupAssistanceCoreData.nextPickup,
    );
    return {
      ...pickupAssistanceCoreData,
      ...assistanceTexts,
    };
  },
};
</script>

<style scoped lang="scss">
@use '@/theme/dbStyle';

.journey-overview {
  --background: #{dbStyle.color('cool-gray', 800)};
  --color: #{dbStyle.color('cool-gray', 100)};

  font-weight: bold;

  .delay {
    color: dbStyle.$puenktlich;
    &.over5 {
      color: dbStyle.$verspaetet;
    }
  }
}
</style>
