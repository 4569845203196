export class Identifikation {
  readonly quelle: Quelle;
  readonly id: string;

  constructor(reiseQuelle: Quelle, reiseId: string) {
    this.quelle = reiseQuelle;
    this.id = reiseId;
  }

  toString(): string {
    return `${this.quelle}-${this.id}`;
  }
}

export enum Quelle {
  DB_BUSINESS_NAVIGATOR,
  DB_NAVIGATOR,
  OEBB,
  SBB,
  IRIS,
  MUY_VLAK,
  NS,
  PKP,
  SNCF_CONNECT,
  UNBEKANNT = 999,
}

export function displayName(reiseQuelle: Quelle) {
  switch (reiseQuelle) {
    case Quelle.DB_BUSINESS_NAVIGATOR:
      return 'DB Business Navigator';
    case Quelle.DB_NAVIGATOR:
      return 'DB Navigator';
    case Quelle.OEBB:
      return 'ÖBB';
    case Quelle.SBB:
      return 'SBB Mobile';
    case Quelle.IRIS:
      return 'IRIS';
    case Quelle.MUY_VLAK:
      return 'Můj vlak';
    case Quelle.NS:
      return 'NS';
    case Quelle.PKP:
      return 'PKP Intercity';
    case Quelle.SNCF_CONNECT:
      return 'SNCF Connect';
    case Quelle.UNBEKANNT:
      return 'Unbekannt';
  }
}
