export type Meldung = HimMeldung | RisMeldung;

export interface HimMeldung {
  quelle: MeldungQuelle.HIM;
  id: string;
  titel: string;
  text: string;
}

export interface RisMeldung {
  quelle: MeldungQuelle.RIS;
  id: string;
  typ: RisMeldungTyp;
  zeitpunkt: Date;
  code: number;
  isErsetzt: boolean;
}

export enum MeldungQuelle {
  RIS,
  HIM,
}

export enum RisMeldungTyp {
  VERSPAETUNG,
  SERVICEQUALITAET,
}

export function isHimMeldung(m: Meldung): m is HimMeldung {
  return m.quelle === MeldungQuelle.HIM;
}

export function isRisMeldung(m: Meldung): m is RisMeldung {
  return m.quelle === MeldungQuelle.RIS;
}
