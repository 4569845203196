import { addYears, type Duration, format, isToday, isTomorrow, startOfToday, subYears } from 'date-fns';
import { DATE_FORMAT } from '@/constants';
import { type PickupsByDate, type ReiseByDate } from '@/types';
import { type Reise } from '@/model/reise';
import type { LastAbschnittPickup } from '@/stores/pickupAssistance';

const today = new Date();
export const IonDateTimeMax = format(addYears(today, 1), 'yyyy');
export const IonDateTimeMin = format(subYears(today, 1), 'yyyy');

export function formatDateForUser(date: number | Date) {
  const formattedTime = format(date, 'HH:mm');
  let formattedDate: string;
  if (isToday(date)) {
    formattedDate = 'Heute';
  } else if (isTomorrow(date)) {
    formattedDate = 'Morgen';
  } else {
    formattedDate = format(date, 'dd.MM.yyyy');
  }
  return `${formattedDate}, ${formattedTime} Uhr`;
}

export function isInt(s: string | null | undefined): s is string {
  return s !== null && s !== undefined && !Number.isNaN(Number.parseInt(s, 10));
}

export function convertRoutesToRoutesByDate(result: ReiseByDate, route: Reise): ReiseByDate {
  const date = format(route.abfahrt.sollZeit, DATE_FORMAT);
  if (result[date] !== undefined) {
    const insertIndex = result[date].findIndex((r) => route.abfahrt.sollZeit < r.abfahrt.sollZeit);
    const index = insertIndex > -1 ? insertIndex : result[date].length;
    result[date].splice(index, 0, route);
  } else {
    result[date] = [route];
  }
  return result;
}

export function convertPickupsToPickupsByDate(res: PickupsByDate, pickup: LastAbschnittPickup) {
  const date = pickup.lastLeg.ankunft
    ? format(pickup.lastLeg.ankunft.fahrtereignis.sollZeit, DATE_FORMAT)
    : format(startOfToday(), DATE_FORMAT);
  if (res[date] !== undefined) {
    const insertIndex = res[date].findIndex(
      (r) => pickup.lastLeg.ankunft.fahrtereignis.sollZeit < r.lastLeg.ankunft.fahrtereignis.sollZeit,
    );
    const index = insertIndex > -1 ? insertIndex : res[date].length;
    res[date].splice(index, 0, pickup);
  } else {
    res[date] = [pickup];
  }
  return res;
}

export function formatDurationInHours(duration: Duration): string {
  return `${duration.hours || 0}:${duration.minutes?.toString(10).padStart(2, '0') || '00'}`;
}

export function formatDurationInHoursWithH(duration: Duration): string {
  return `${formatDurationInHours(duration)}h`;
}
