/// <reference lib="es2021.string" />

import { Share } from '@capacitor/share';
import { ref } from 'vue';

const canShare = ref(false);

const determineSharingCapability = () => {
  Share.canShare().then((result) => (canShare.value = result.value));
};
const share = async (title: string, text: string, path: string) => {
  try {
    await Share.share({
      title,
      text,
      url: `https://zentrains.de/${path.replaceAll(' ', '%20')}`,
      dialogTitle: `${title} teilen`,
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e: unknown) {
    // noop
  }
};

export default function useSharing() {
  return {
    canShare,
    determineSharingCapability,
    share,
  };
}
