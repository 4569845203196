import { type HimMeldung, type Meldung, MeldungQuelle, type RisMeldung } from '@/model/meldung';
import { type MeldungDto } from '@/model/dto/MeldungDto';

export function mapDtoToMeldung(meldung: MeldungDto): Meldung {
  switch (meldung.quelle) {
    case MeldungQuelle.RIS:
      return {
        quelle: meldung.quelle,
        id: meldung.id,
        typ: meldung.typ,
        zeitpunkt: new Date(meldung.zeitpunkt),
        code: meldung.code,
        isErsetzt: meldung.isErsetzt,
      } as RisMeldung;
    case MeldungQuelle.HIM:
      return {
        quelle: meldung.quelle,
        id: meldung.id,
        titel: meldung.titel,
        text: meldung.text,
      } as HimMeldung;
  }
}
