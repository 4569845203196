import { Baureihe } from '@/model/baureihe';
import { AuslastungWert } from '@/model/common';
import { type SitzplatzGruppe } from '@/model/sonderSitzplatz';

export interface Wagenreihung {
  readonly isCached: boolean;
  readonly quelle: string;
  readonly fahrtNummer: number;
  readonly fahrtGattung: string;
  readonly hasFluegel: boolean;
  readonly isMatchingPlan: boolean;
  readonly fahrtrichtung: Fahrtrichtung;
  readonly anfangAmHalt: number;
  readonly skalierung: number;
  readonly allSektor: Sektor[];
  readonly allWagenGruppe: WagenGruppe[];
}

export enum Fahrtrichtung {
  LINKS,
  RECHTS,
}

export interface WagenGruppe {
  readonly allWagen: Wagen[];
  readonly bezeichnung: string;
  readonly extraBezeichung: string | null;
  readonly baureihe: Baureihe;
  readonly fahrtNummer: number;
  readonly triebzugNummer: number | null;
  readonly startHalt: string;
  readonly zielHalt: string;
  /**
   * Position des Anfangs des ersten Wagens am Halt in Prozent
   */
  readonly anfangAmHalt: number;
  /**
   * Position des Endes des letzten Wagens am Halt in Prozent
   */
  readonly endeAmHalt: number;
}

export interface Sektor {
  readonly name: string;
  /**
   * Position des Sektor-Anfangs am Halt in Prozent
   */
  readonly anfangAmHalt: number;
  /**
   * Position des Sektor-Endes am Halt in Prozent
   */
  readonly endeAmHalt: number;
}

export interface Wagen {
  readonly bauart: string;
  readonly uicNummer: string | null;
  readonly wagenordnungNummer: string | null;
  readonly klasse: number;
  readonly sektor: string;
  readonly status: WagenStatus;
  readonly auslastung: AuslastungWert | null;
  readonly allSonderSitzplatzGruppe: SitzplatzGruppe[];
  readonly allCSS: WagenCSS;
}

export enum WagenStatus {
  OFFEN,
  GESCHLOSSEN,
}

export interface WagenCSS {
  /**
   * Position des Wagenanfangs am Halt in Prozent
   */
  anfangAmHalt: number;
  /**
   * Position des Wagenendes am Halt in Prozent
   */
  endeAmHalt: number;
  allFahrzeugEigenschaftenKlasse: FahrzeugEigenschaftenKlasse[];
  allFahrzeugKlasse: FahrzeugKlasse[];
  allTextKlasse: TextKlasse[];
}

export enum TextKlasse {
  GESCHLOSSEN = 'geschlossen',
}

export enum FahrzeugKlasse {
  LOK = 'lok',
  STEUERWAGEN = 'steuerwagen',
  TRIEBKOPF = 'triebkopf',
  WAGEN = 'wagen',
}

export enum FahrzeugEigenschaftenKlasse {
  COMFORT_LINKS = 'comfortlinks',
  COMFORT_RECHTS = 'comfortrechts',
  ERSTE_KLASSE = 'ersteklasse',
  ERSTE_KLASSE_LINKS = 'ersteklasselinks',
  ERSTE_KLASSE_RECHTS = 'ersteklasserechts',
  ERSTE_KLASSE_OBEN = 'ersteklasseoben',
  ZWEITE_KLASSE = 'zweiteklasse',
  ZWEITE_KLASSE_LINKS = 'zweiteklasselinks',
  ZWEITE_KLASSE_RECHTS = 'zweiteklasserechts',
  LIEGEWAGEN = 'liegewagen',
  RESTAURANT = 'restaurant',
  KIOSK = 'kiosk',
  SCHLAFWAGEN = 'schlafwagen',
  DOPPELSTOCK = 'doppelstock',
}
