import { defineStore } from 'pinia';
import { Toast } from '@capacitor/toast';
import { useSettingsStore } from '@/stores/settings';
import { usePickupAssistanceStore } from '@/stores/pickupAssistance';
import { useReiseBegleitungStore } from '@/stores/reiseBegleitung';
import { useBackendStore } from '@/stores/backend';
import { notification } from '@zentrains/api';
import { mapToExternalReiseItem } from '@/mapper/to-api/reiseItem';

export interface NotificationState {
  pushToken: string;
  activePushMethod: ActivePushMethod;
}

export enum ActivePushMethod {
  NONE,
  SERVICE_WORKER,
  NATIVE,
}

export const useNotificationsStore = defineStore('notifications', {
  state: (): NotificationState => ({
    pushToken: '',
    activePushMethod: ActivePushMethod.NONE,
  }),
  persist: true,
  actions: {
    activatePush(token: string, method: ActivePushMethod) {
      this.pushToken = token;
      this.activePushMethod = method;
    },
    async deactivatePush() {
      if (this.pushToken !== '') {
        const backend = useBackendStore();
        const request: notification.DeviceKeyRequest = {
          deviceKey: this.pushToken,
        };
        await backend.notificationClient.deleteDevice(request);
        this.activePushMethod = ActivePushMethod.NONE;
        this.pushToken = '';
      }
    },
    async sendPushTokenToServer() {
      if (this.pushToken !== '') {
        const backend = useBackendStore();
        const request: notification.DeviceKeyRequest = {
          deviceKey: this.pushToken,
        };
        await backend.notificationClient.registerDevice(request);
      }
    },
    async sendAllDataToNotificationServer() {
      await this.sendPushTokenToServer();
      const settings = useSettingsStore();
      await settings.sendCheckinTokensToServer();
      await settings.sendNotificationThresholdsToServer();
      await this.sendAllReiseToNotificationServer();
      const pickupAssistanceStore = usePickupAssistanceStore();
      await pickupAssistanceStore.sendPickupsToNotificationServer();
    },
    async sendAllReiseToNotificationServer() {
      const settings = useSettingsStore();
      const reiseBegleitungStore = useReiseBegleitungStore();
      if (settings.pushNotificationsActive && this.pushToken !== '') {
        const request: notification.SaveAllReiseRequest = {
          deviceKey: this.pushToken,
          allReiseItem: reiseBegleitungStore.allReise.map(mapToExternalReiseItem),
        };
        const backend = useBackendStore();
        await backend.notificationClient.saveAllReise(request);
      }
    },
    async requestTestNotification() {
      if (this.pushToken !== '') {
        const backend = useBackendStore();
        const request: notification.DeviceKeyRequest = {
          deviceKey: this.pushToken,
        };
        await backend.notificationClient.requestTestNotification(request);
        await Toast.show({
          text: 'Push-Benachrichtigung wurde angefordert.',
          duration: 'short',
        });
      } else {
        await Toast.show({
          text: 'Push-Benachrichtigungen sind nicht aktiv.',
          duration: 'short',
        });
      }
    },
  },
});
