import { Toast } from '@capacitor/toast';
import { computed } from 'vue';
import { useNotificationsStore } from '@/stores/notifications';
import { useBackendStore } from '@/stores/backend';

export default function useResetCheckin() {
  const notificationStore = useNotificationsStore();
  const deviceKey = computed(() => notificationStore.pushToken);

  const resetCheckin = async () => {
    const backend = useBackendStore();
    const response = await backend.notificationClient.resetCheckin({
      deviceKey: deviceKey.value,
    });
    await Toast.show({
      text: response.message,
      duration: 'short',
    });
  };

  return {
    resetCheckin,
  };
}
