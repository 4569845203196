import { model } from '@zentrains/api';
import { AbschnittTyp } from '@/model/abschnitt';

export function mapExternalAbschnittTyp(abschnittTyp: model.AbschnittTyp): AbschnittTyp {
  switch (abschnittTyp) {
    case model.AbschnittTyp.AbschnittFahrt:
      return AbschnittTyp.Fahrt;
    case model.AbschnittTyp.AbschnittFussweg:
      return AbschnittTyp.Fussweg;
    case model.AbschnittTyp.AbschnittTransfer:
      return AbschnittTyp.Transfer;
    case model.AbschnittTyp.UNRECOGNIZED:
    default:
      return AbschnittTyp.Fahrt;
  }
}
