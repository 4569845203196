import { computed } from 'vue';
import { ActivePushMethod, useNotificationsStore } from '@/stores/notifications';

export default function usePushNotifications() {
  const notificationStore = useNotificationsStore();
  const activePushMethod = computed(() => {
    switch (notificationStore.activePushMethod) {
      case ActivePushMethod.SERVICE_WORKER:
        return 'Service Worker';
      case ActivePushMethod.NATIVE:
        return 'Nativ';
      case ActivePushMethod.NONE:
      default:
        return 'Nicht aktiviert';
    }
  });
  const deviceKey = computed(() => notificationStore.pushToken.split(':')[0]);
  const requestTestNotification = async () => notificationStore.requestTestNotification();

  return {
    activePushMethod,
    deviceKey,
    requestTestNotification,
  };
}
