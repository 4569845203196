import { type Abschnitt, type AbschnittFluegel } from '@/model/abschnitt';
import { type AbschnittDto } from '@/model/dto/AbschnittDto';
import { mapFahrtToDto } from '@/mapper/to-store-dto/fahrt';
import { mapAbschnittFahrtereignisToDto } from '@/mapper/to-store-dto/abschnittFahrtereignis';
import { mapHaltToDto } from '@/mapper/to-store-dto/halt';
import { mapMeldungToDto } from '@/mapper/to-store-dto/meldung';
import { type AbschnittFluegelDto } from '@/model/dto/AbschnittFluegelDto';

export function mapAbschnittToDto(abschnitt: Abschnitt): AbschnittDto {
  return {
    fahrtId: abschnitt.fahrtId,
    typ: abschnitt.typ,
    fahrt: mapFahrtToDto(abschnitt.fahrt),
    abfahrt: mapAbschnittFahrtereignisToDto(abschnitt.abfahrt),
    ankunft: mapAbschnittFahrtereignisToDto(abschnitt.ankunft),
    dauer: abschnitt.dauer,
    isAusfall: abschnitt.isAusfall,
    isErreichbar: abschnitt.isErreichbar,
    auslastung: abschnitt.auslastung,
    umstiegZeit: abschnitt.umstiegDauer,
    umstiegBewertung: abschnitt.umstiegBewertung,
    allFluegel: abschnitt.allFluegel.map(mapAbschnittFluegelToDto),
    allHalt: abschnitt.allHalt.map(mapHaltToDto),
    allMeldung: abschnitt.allMeldung.map(mapMeldungToDto),
  };
}

export function mapAbschnittFluegelToDto(fluegel: AbschnittFluegel): AbschnittFluegelDto {
  return {
    fahrtId: fluegel.fahrtId,
    fahrt: mapFahrtToDto(fluegel.fahrt),
    allMeldung: fluegel.allMeldung.map(mapMeldungToDto),
  };
}
