export const ROUTE = {
  HOME: 'Home',
  OMNI_SEARCH: 'OmniSearch',
  STATION_BOARD: 'Abfahrtstafel',
  TICKET_DETAILS: 'Ticket-Details',
  TRAIN_DETAILS: 'Zug-Details',
  ROUTE_DETAILS: 'Verbindungs-Details',
  ROUTE_SEARCH: 'Verbindungen',
  ALTERNATIVE_SEARCH: 'Alternativen-Suche',
  ALTERNATIVE_ROUTE: 'Alternative Verbindung',
  MY_ROUTES: 'Meine Reisen',
  MY_PICKUPS: 'Abholen',
  MY_ROUTE_DETAILS: 'Meine Verbindung',
  SETTINGS: 'Einstellungen',
  ABOUT: 'Über die App',
  TRAEWELLING_OAUTH_CALLBACK: 'Träwelling-Login',
};
