export interface SitzplatzGruppe {
  typ: SitzplatzGruppeTyp;
  sitzPlaetze: string;
}

export enum SitzplatzGruppeTyp {
  BAHNBONUS,
  EXPRESSRESERVIERUNG,
  MOBILITAETSEINGESCHRAENKT,
  ROLLSTUHL,
}

export function mapSitzplatzGruppeTypToText(sitzplatzGruppeTyp: SitzplatzGruppeTyp): string {
  switch (sitzplatzGruppeTyp) {
    case SitzplatzGruppeTyp.BAHNBONUS:
      return 'Status-Sitzplätze';
    case SitzplatzGruppeTyp.EXPRESSRESERVIERUNG:
      return 'Express-Reservierung';
    case SitzplatzGruppeTyp.MOBILITAETSEINGESCHRAENKT:
      return 'MER-Sitzplätze';
    case SitzplatzGruppeTyp.ROLLSTUHL:
      return 'Rollstuhl-Sitzplätze';
  }
}
