import { type LastAbschnittPickup, usePickupAssistanceStore } from '@/stores/pickupAssistance';
import { computed, onBeforeUnmount, ref, watch } from 'vue';
import { differenceInSeconds, getSeconds, isBefore, isToday, startOfMinute } from 'date-fns';
import { type Abschnitt } from '@/model/abschnitt';

function generateSlug(pickup: LastAbschnittPickup): string {
  return `${pickup.pickup.name}_${pickup.lastLeg.fahrt.getName()}`;
}

function havePickupsChanged(
  newPickups: LastAbschnittPickup[],
  prevPickups: LastAbschnittPickup[] | undefined,
): boolean {
  return prevPickups?.map(generateSlug).join('-') !== newPickups.map(generateSlug).join('-');
}

function arePickupsInvalid(currentTime: number, nextPickup: Abschnitt | null): boolean {
  const curTime = startOfMinute(currentTime);
  if (nextPickup !== null) {
    const arrival = nextPickup.ankunft.fahrtereignis.getZeit();
    const diff = differenceInSeconds(arrival, curTime);
    return diff <= 0;
  }
  return true;
}

export default function usePickupAssistanceCoreData() {
  const pickupAssistanceStore = usePickupAssistanceStore();
  const pickups = computed(() => pickupAssistanceStore.lastLegs);

  const isRefreshingPickups = computed(() => pickupAssistanceStore.updating);

  const nextPickupIndex = ref(-1);
  const nextPickup = computed(() => (nextPickupIndex.value > -1 ? pickups.value[nextPickupIndex.value] : null));

  const currentTime = ref(Date.now());
  const currentTimeInterval = ref(
    window.setInterval(() => {
      currentTime.value = Date.now();
    }, 2000),
  );
  onBeforeUnmount(() => window.clearInterval(currentTimeInterval.value));

  watch(
    [pickups, currentTime],
    ([pickups, time], [prevPickups]) => {
      const currentSeconds = getSeconds(time);
      if (
        currentSeconds >= 58 ||
        currentSeconds <= 2 ||
        havePickupsChanged(pickups, prevPickups) ||
        arePickupsInvalid(currentTime.value, nextPickup.value?.lastLeg || null)
      ) {
        // Suche nächste Ankunft
        nextPickupIndex.value = pickups.findIndex((leg) => {
          const arrival = leg.lastLeg.ankunft.fahrtereignis.getZeit();
          return isToday(arrival) && isBefore(time, arrival);
        });
      }
    },
    {
      immediate: true,
    },
  );

  return {
    currentTime,
    nextPickupIndex,
    nextPickup,
    isRefreshingPickups,
  };
}
