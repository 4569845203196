import { model } from '@zentrains/api';
import { type Abschnitt, UmstiegBewertung } from '@/model/abschnitt';
import { mapExternalFahrt } from '@/mapper/from-api/fahrt';
import { mapExternalAbschnittFahrtereignis } from '@/mapper/from-api/abschnittFahrtereignis';
import { mapExternalDauer } from '@/mapper/from-api/helper';
import { mapExternalHalt } from '@/mapper/from-api/halt';
import { mapExternalMeldung } from '@/mapper/from-api/meldung';
import { mapExternalAuslastung } from '@/mapper/from-api/auslastung';
import { mapExternalAbschnittFluegel } from '@/mapper/from-api/abschnittFluegel';
import { mapExternalAbschnittTyp } from '@/mapper/from-api/common';

export function mapExternalAbschnitt(externalAbschnitt: model.Abschnitt): Abschnitt {
  const externalFahrt = externalAbschnitt.fahrt;
  if (externalFahrt === undefined) {
    throw 'Fahrt in Abschnitt undefined, which is illegal';
  }
  const externalAbfahrt = externalAbschnitt.abfahrt;
  if (externalAbfahrt === undefined) {
    throw 'Abfahrt in Abschnitt undefined, which is illegal';
  }
  const externalAnkunft = externalAbschnitt.ankunft;
  if (externalAnkunft === undefined) {
    throw 'Ankunft in Abschnitt undefined, which is illegal';
  }

  const externalAuslastung = externalAbschnitt.auslastung;
  const umstiegBewertung = externalAbschnitt.umstiegBewertung;
  return {
    fahrtId: externalAbschnitt.fahrtId,
    typ: mapExternalAbschnittTyp(externalAbschnitt.typ),
    fahrt: mapExternalFahrt(externalFahrt),
    abfahrt: mapExternalAbschnittFahrtereignis(externalAbfahrt),
    ankunft: mapExternalAbschnittFahrtereignis(externalAnkunft),
    dauer: mapExternalDauer(externalAbschnitt.dauer),
    umstiegDauer: mapExternalDauer(externalAbschnitt.umstiegDauer),
    isAusfall: externalAbschnitt.isAusfall,
    isErreichbar: externalAbschnitt.isErreichbar,
    allFluegel: externalAbschnitt.allFluegel.map(mapExternalAbschnittFluegel),
    allHalt: externalAbschnitt.allHalt.map(mapExternalHalt),
    allMeldung: externalAbschnitt.allMeldung.map(mapExternalMeldung),
    auslastung: mapExternalAuslastung(externalAuslastung !== undefined ? externalAuslastung : null),
    umstiegBewertung: mapExternalUmstiegBewertung(umstiegBewertung !== undefined ? umstiegBewertung : null),
  };
}

export function mapExternalUmstiegBewertung(
  externalUmstiegBewertung: model.UmstiegBewertung | null,
): UmstiegBewertung | null {
  if (externalUmstiegBewertung === null) {
    return null;
  }
  switch (externalUmstiegBewertung) {
    case model.UmstiegBewertung.UmstiegGesichert:
      return UmstiegBewertung.Gesichert;
    case model.UmstiegBewertung.UmstiegWahrscheinlich:
      return UmstiegBewertung.Wahrscheinlich;
    case model.UmstiegBewertung.UmstiegKeineInformation:
      return UmstiegBewertung.KeineInformation;
    case model.UmstiegBewertung.UmstiegGefaehrdet:
      return UmstiegBewertung.Gefaehrdet;
    case model.UmstiegBewertung.UmstiegAbgelehnt:
      return UmstiegBewertung.Abgelehnt;
    case model.UmstiegBewertung.UmstiegUnbekannt:
      return UmstiegBewertung.Unbekannt;
    case model.UmstiegBewertung.UNRECOGNIZED:
    default:
      return UmstiegBewertung.Unbekannt;
  }
}
