import { useReiseBegleitungStore } from '@/stores/reiseBegleitung';
import { type RefresherEventDetail } from '@/types';

export default function useReiseBegleitung() {
  const reiseBegleitungStore = useReiseBegleitungStore();
  const refreshAllReiseFromRefresher = async (event: CustomEvent) => {
    await reiseBegleitungStore.refreshAllReise();
    const eventTarget = event.target;
    if (eventTarget !== null) {
      (eventTarget as unknown as RefresherEventDetail).complete();
    }
  };

  return {
    reiseBegleitungStore,
    refreshAllReiseFromRefresher,
  };
}
