<template>
  <ion-footer v-if="!hasNoRelevantLegs" class="ion-no-border">
    <ion-toolbar class="journey-overview">
      <div class="ion-text-center">
        <div class="first-line">
          {{ command }}
          <span class="time">
            {{ time }}h
            <span v-if="hasDelay" class="delay" :class="{ over5: isDelayOver5Minutes }">{{ delay }}</span>
          </span>
          {{ station }}
        </div>
        <div class="second-line"></div>
      </div>
    </ion-toolbar>
  </ion-footer>
</template>

<script lang="ts">
import useReiseBegleitungCoreData from '@/composables/reise-begleitung/useReiseBegleitungCoreData';
import useReiseBegleitungTexts from '@/composables/reise-begleitung/useReiseBegleitungTexts';
import { IonFooter, IonToolbar } from '@ionic/vue';
import useCurrentTime from '@/composables/useCurrentTime';

export default {
  name: 'JourneyOverlay',
  components: {
    IonFooter,
    IonToolbar,
  },
  setup() {
    const { currentTime } = useCurrentTime();
    const { hasNoRelevantLegs, isCurrentlyOnTrain, lastDeparture, nextArrival, nextDeparture } =
      useReiseBegleitungCoreData(currentTime);

    const assistanceTexts = useReiseBegleitungTexts(
      currentTime,
      isCurrentlyOnTrain,
      lastDeparture,
      nextArrival,
      nextDeparture,
    );
    return {
      hasNoRelevantLegs,
      ...assistanceTexts,
    };
  },
};
</script>

<style scoped lang="scss">
@use '@/theme/dbStyle';

.journey-overview {
  --background: #{dbStyle.color('cool-gray', 800)};
  --color: #{dbStyle.color('cool-gray', 100)};

  font-weight: bold;

  .delay {
    color: dbStyle.$puenktlich;
    &.over5 {
      color: dbStyle.$verspaetet;
    }
  }
}
</style>
