export enum Baureihe {
  UNBEKANNT,
  BR101,
  BR146,
  BR193,
  BR401,
  BR401_LDV,
  BR401_9T,
  BR402,
  BR403R,
  BR406,
  BR406R,
  BR407,
  BR408,
  BR410_1,
  BR411,
  BR411_S1,
  BR411_S2,
  BR412,
  BR412_7T,
  BR412_13T,
  BR415,
  BR423,
  BR425,
  BR427,
  BR430,
  BR440,
  BR442,
  /** Bombardier TWINDEXX Vario **/
  BR445,
  /** Bombardier TWINDEXX Vario **/
  BR446,
  BR462,
  BR463,
  /** Alstom Coradia iLINT **/
  BR554,
  BR612,
  /** Alstom Coradia LINT 81 **/
  BR620,
  /** Alstom Coradia LINT 54 **/
  BR622,
  /** Alstom Coradia LINT 54 **/
  BR1622,
  /** Alstom Coradia LINT 54 **/
  BR623,
  BR628,
  BR632,
  BR633,
  /** Alstom Coradia LINT 27 **/
  BR640,
  BR642,
  BR643,
  /** Alstom Coradia LINT 41 **/
  BR648,
  /** Alstom Coradia LINT 41 **/
  BR1648,
  /** Alstom Coradia LINT 41 **/
  BR2648,
  BR650,
  IC2_TWIN,
  IC2_KISS,
  TGV,
  EC,
  IC,
  RJ,
  ETR610,
  NJ,
  /**
   * @deprecated use specific BR instead
   **/
  LINT,
  DOSTO,
  DSB_IC3,
  OEBB_4024,
  OEBB_4746,
  OEBB_1016,
  OEBB_1116,
  OEBB_1216,
  OEBB_CJX,
  OEBB_DOSTO,
  OEBB_4023,
}
