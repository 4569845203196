import { computed } from 'vue';
import { Toast } from '@capacitor/toast';
import { isInt } from '@/composables/helper';
import { useSettingsStore } from '@/stores/settings';
import { useNotificationsStore } from '@/stores/notifications';
import { useStationMRUStore } from '@/stores/stationMRU';
import { useReiseBegleitungStore } from '@/stores/reiseBegleitung';
import { useBackendStore } from '@/stores/backend';

export default function useSettings() {
  const settingsStore = useSettingsStore();
  const pushNotificationsActive = computed(() => settingsStore.pushNotificationsActive);
  const searchForBetriebsstelle = settingsStore.searchForBetriebsstelle;
  const showDetails = settingsStore.showDetails;
  const checkinActive = settingsStore.checkinActive;
  const thresholdChangeMinutes = settingsStore.thresholdChangeNotificationMinutes;
  const thresholdDepartureMinutes = settingsStore.thresholdDepartureNotificationMinutes;
  const thresholdFetchMinutes = settingsStore.thresholdFetchNotificationMinutes;
  const travelynxToken = settingsStore.travelynxToken;
  const unlockCodes = settingsStore.unlockCodes;
  const setPushNotificationsActive = (value: boolean) => {
    settingsStore.pushNotificationsActive = value;
  };
  const setShowDetails = (value: boolean) => {
    settingsStore.showDetails = value;
  };
  const setSearchForBetriebsstelle = (value: boolean) => {
    settingsStore.searchForBetriebsstelle = value;
  };
  const setCheckinActive = (value: boolean) => {
    settingsStore.checkinActive = value;
  };
  const setThresholdChangeMinutes = (value: string | null | undefined) =>
    (settingsStore.thresholdChangeNotificationMinutes = isInt(value)
      ? Number.parseInt(value, 10)
      : thresholdChangeMinutes);
  const setThresholdDepartureMinutes = (value: string | null | undefined) =>
    (settingsStore.thresholdDepartureNotificationMinutes = isInt(value)
      ? Number.parseInt(value, 10)
      : thresholdDepartureMinutes);
  const setThresholdFetchMinutes = (value: string | null | undefined) =>
    (settingsStore.thresholdFetchNotificationMinutes = isInt(value)
      ? Number.parseInt(value, 10)
      : thresholdFetchMinutes);
  const setTravelynxToken = (value: string | null | undefined) => {
    if (value !== null && value !== undefined) {
      settingsStore.travelynxToken = value;
    }
  };
  const setUnlockCodes = (value: string | null | undefined) => {
    if (value !== null && value !== undefined) {
      settingsStore.unlockCodes = value;
    }
  };

  const notificationStore = useNotificationsStore();
  const clearAllSavedRoutes = async () => {
    const reiseBegleitungStore = useReiseBegleitungStore();
    reiseBegleitungStore.clearAllReise();
    await notificationStore.sendAllReiseToNotificationServer();
    await Toast.show({
      text: 'Alle gespeicherten Verbindungen wurden gelöscht.',
    });
  };

  const clearAllSentNotifications = async () => {
    const backend = useBackendStore();
    await backend.notificationClient.resetSentNotifications({
      deviceKey: notificationStore.pushToken,
    });
    await Toast.show({
      text: 'Alle gesendeten Push-Benachrichtigungen wurden zurückgesetzt.',
    });
  };

  const stationMRUStore = useStationMRUStore();
  const clearStationMRU = async () => {
    stationMRUStore.stations = {};
    await Toast.show({
      text: 'Alle häufig benutzten Stationen wurden gelöscht.',
    });
  };

  const sendRelevantDataToNotificationManager = async () =>
    await useNotificationsStore().sendAllDataToNotificationServer();

  const arePushNotificationsActive = computed(() => settingsStore.pushNotificationsActive);
  const isCheckinActive = computed(() => settingsStore.checkinActive);
  const isTroubleshootingUnlocked = computed(() => settingsStore.isTroubleshootingUnlocked);
  const isBackendSelectionUnlocked = computed(() => settingsStore.isBackendSelectionUnlocked);

  return {
    pushNotificationsActive,
    searchForBetriebsstelle,
    showDetails,
    checkinActive,
    thresholdChangeMinutes,
    thresholdDepartureMinutes,
    thresholdFetchMinutes,
    travelynxToken,
    unlockCodes,
    setPushNotificationsActive,
    setSearchForBetriebsstelle,
    setShowDetails,
    setCheckinActive,
    setThresholdChangeMinutes,
    setThresholdDepartureMinutes,
    setThresholdFetchMinutes,
    setTravelynxToken,
    setUnlockCodes,
    arePushNotificationsActive,
    isCheckinActive,
    isTroubleshootingUnlocked,
    isBackendSelectionUnlocked,
    clearAllSavedRoutes,
    clearAllSentNotifications,
    clearStationMRU,
    sendRelevantDataToNotificationManager,
  };
}
