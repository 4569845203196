import { defineStore } from 'pinia';
import SERVICE_WORKER_ENABLED from '@/env/serviceWorkerEnabled';
import BUILD_TARGET from '@/env/buildTarget';
import ENVIRONMENT from '@/env/environment';
import VERSION from '@/env/version';

export const useEnvStore = defineStore('env', {
  getters: {
    buildTarget: () => BUILD_TARGET,
    environment: () => ENVIRONMENT,
    isServiceWorkerEnabled: () => SERVICE_WORKER_ENABLED === 'true',
    version: () => VERSION,
  },
});
