import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { pinia } from '@/stores';
import VERSION from '@/env/version';

import { IonicVue } from '@ionic/vue';
import { ObserveVisibility } from 'vue-observe-visibility';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import { browserTracingIntegration, init } from '@sentry/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.scss';

import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import ENVIRONMENT from '@/env/environment';

const app = createApp(App).use(pinia).use(IonicVue).use(router).directive('observe-visibility', ObserveVisibility);

init({
  app,
  dsn: 'https://8f363734a4e34dfbbea2bc6ebb064c41@log.zenti.cloud/2',
  autoSessionTracking: true,
  integrations: [browserTracingIntegration()],
  release: VERSION,
  environment: ENVIRONMENT,
  attachStacktrace: true,
  logErrors: ENVIRONMENT !== 'production',

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: ENVIRONMENT === 'production' ? 0.1 : 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/api\.zentrains\.de.*/],
});

router.isReady().then(async () => {
  app.mount('#app');
  await defineCustomElements(window);
});
