import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { type RouteLocationPathRaw, useRoute, useRouter } from 'vue-router';
import { App } from '@capacitor/app';
const key = 'lastRoute';

export default function useNativePersistentState() {
  const route = useRoute();
  const router = useRouter();
  const storeRoute = async () => {
    await Preferences.set({
      key,
      value: JSON.stringify({
        path: route.path,
        params: route.params,
        query: route.query,
      }),
    });
  };
  const restoreRoute = async () => {
    if (Capacitor.isNativePlatform()) {
      const lastRoute = await Preferences.get({ key });
      if (lastRoute.value !== null) {
        await Preferences.remove({ key });
        const targetRoute: RouteLocationPathRaw = JSON.parse(lastRoute.value);
        if (targetRoute.path !== '/') {
          await router.push(targetRoute);
        }
      }
    }
  };

  const setupRoutePersistence = () => {
    App.addListener('appStateChange', async (state) => {
      if (!state.isActive) {
        await storeRoute();
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    }).then((_) => {
      /*noop*/
    });
  };

  return {
    restoreRoute,
    setupRoutePersistence,
  };
}
