import { Fahrtereignis } from '@/model/fahrtereignis';
import { type Auslastung } from '@/model/auslastung';
import { type Meldung } from '@/model/meldung';
import { type Haltestelle } from '@/model/haltestelle';

export class Halt {
  readonly abfahrt: Fahrtereignis | null;
  readonly ankunft: Fahrtereignis | null;

  readonly haltestelle: Haltestelle;
  readonly auslastung: Auslastung | null;

  readonly allMeldung: Meldung[];

  constructor(
    abfahrt: Fahrtereignis | null,
    ankunft: Fahrtereignis | null,
    haltestelle: Haltestelle,
    auslastung: Auslastung | null,
    allMeldung: Meldung[],
  ) {
    this.abfahrt = abfahrt;
    this.ankunft = ankunft;
    this.haltestelle = haltestelle;
    this.auslastung = auslastung;
    this.allMeldung = allMeldung;
  }

  hasGleiswechsel(): boolean {
    if (this.abfahrt !== null) {
      return (
        this.abfahrt.sollGleis !== '' &&
        this.abfahrt.progGleis !== null &&
        this.abfahrt.progGleis !== this.abfahrt.sollGleis
      );
    } else if (this.ankunft !== null) {
      return (
        this.ankunft.sollGleis !== '' &&
        this.ankunft.progGleis !== null &&
        this.ankunft.progGleis !== this.ankunft.sollGleis
      );
    }
    throw 'Neither Abfahrt nor Ankunft is set, which is illegal';
  }

  isAusfall(): boolean {
    if (this.abfahrt !== null && this.ankunft !== null) {
      return this.abfahrt.isAusfall && this.ankunft.isAusfall;
    } else if (this.abfahrt !== null) {
      return this.abfahrt.isAusfall;
    } else if (this.ankunft !== null) {
      return this.ankunft.isAusfall;
    }
    throw 'Neither Abfahrt nor Ankunft is set, which is illegal';
  }

  isZusaetzlich(): boolean {
    if (this.abfahrt !== null && this.ankunft !== null) {
      return this.abfahrt.isZusaetzlich && this.ankunft.isZusaetzlich;
    } else if (this.abfahrt !== null) {
      return this.abfahrt.isZusaetzlich;
    } else if (this.ankunft !== null) {
      return this.ankunft.isZusaetzlich;
    }
    throw 'Neither Abfahrt nor Ankunft is set, which is illegal';
  }
}
