import { computed, type Ref, ref, watch } from 'vue';
import { differenceInMinutes, differenceInSeconds, getSeconds, isAfter, isBefore, startOfMinute } from 'date-fns';
import { NEXT_DEPARTURE_RANGE_IN_MINUTES } from '@/constants';
import { useReiseBegleitungStore } from '@/stores/reiseBegleitung';
import { type Abschnitt } from '@/model/abschnitt';
import { type AbschnittGruppe } from '@/model/abschnittGruppe';

function haveLegsChanged(newLegs: Abschnitt[], prevLegs: Abschnitt[] | undefined): boolean {
  return prevLegs?.map((leg) => leg.fahrt.getName()).join('-') !== newLegs.map((leg) => leg.fahrt.getName()).join('-');
}

function areLegsInvalid(
  currentTime: Date,
  isCurrentlyOnTrain: boolean,
  lastDeparture: Abschnitt | null,
  nextArrival: Abschnitt | null,
  nextDeparture: Abschnitt | null,
): boolean {
  const curTime = startOfMinute(currentTime);
  if (isCurrentlyOnTrain && nextArrival !== null) {
    const arrival = nextArrival?.ankunft.fahrtereignis.getZeit() || 0;
    const diff = differenceInSeconds(arrival, curTime);
    return diff <= 0;
  } else if (nextDeparture !== null) {
    const departure = nextDeparture?.abfahrt.fahrtereignis.getZeit() || 0;
    const diff = differenceInSeconds(departure, curTime);
    return diff <= 0;
  } else if (lastDeparture !== null) {
    const arrival = lastDeparture?.ankunft.fahrtereignis.getZeit() || 0;
    const diff = differenceInSeconds(arrival, curTime);
    return diff <= 0;
  }
  return true;
}

export default function useReiseBegleitungCoreData(currentTime: Ref<Date>) {
  const journeyAssistanceStore = useReiseBegleitungStore();
  const journeyLegs: Ref<Abschnitt[]> = computed(() => journeyAssistanceStore.allAbschnitt);
  const journeyLegGroups: Ref<AbschnittGruppe[]> = computed(() => journeyAssistanceStore.allAbschnittGruppe);

  const isRefreshingJourneys = computed(() => journeyAssistanceStore.updating);

  const lastDepartureIndex = ref(-1);
  const nextArrivalIndex = ref(-1);
  const nextDepartureIndex = ref(-1);

  const currentLegGroupIndex = ref(-1);

  const rawLastDeparture = computed(() =>
    lastDepartureIndex.value > -1 ? journeyLegs.value[lastDepartureIndex.value] : null,
  );
  const nextArrival = computed(() => (nextArrivalIndex.value > -1 ? journeyLegs.value[nextArrivalIndex.value] : null));
  const rawNextDeparture = computed(() =>
    nextDepartureIndex.value > -1 ? journeyLegs.value[nextDepartureIndex.value] : null,
  );

  const isUnreachableAfterPrognosedExit = computed(() => {
    if (rawLastDeparture.value !== null && nextArrival.value !== null) {
      return isAfter(
        rawLastDeparture.value.ankunft.fahrtereignis.getZeit(),
        nextArrival.value.ankunft.fahrtereignis.getZeit(),
      );
    }
    return false;
  });

  const lastDeparture = computed(() =>
    isUnreachableAfterPrognosedExit.value ? nextArrival.value : rawLastDeparture.value,
  );
  const nextDeparture = computed(() =>
    isUnreachableAfterPrognosedExit.value ? rawLastDeparture.value : rawNextDeparture.value,
  );
  const hasNextDeparture = computed(() => nextDeparture.value !== null || isUnreachableAfterPrognosedExit.value);

  const isCurrentlyOnTrain = computed(() => {
    if (lastDeparture.value !== null && nextArrival.value !== null) {
      return (
        isUnreachableAfterPrognosedExit.value ||
        lastDeparture.value?.fahrt.fahrtNummer === nextArrival.value?.fahrt.fahrtNummer
      );
    }
    return false;
  });
  const hasNoLegs = computed(() => journeyLegs.value.length === 0);
  const hasNoRelevantLegs = computed(
    () => (lastDepartureIndex.value === -1 || !isCurrentlyOnTrain.value) && nextDepartureIndex.value === -1,
  );
  const isUnreachableBeforeExit = computed(() => {
    return nextArrival.value !== null &&
      nextDeparture.value !== null &&
      nextArrival.value?.fahrtId !== nextDeparture.value?.fahrtId
      ? isAfter(
          nextArrival.value?.ankunft.fahrtereignis.getZeit() || 0,
          nextDeparture.value?.abfahrt.fahrtereignis.getZeit() || 1,
        )
      : false;
  });
  const isNextDepartureCancelled = computed(() => {
    if (nextDeparture.value !== null) {
      return nextDeparture.value?.isAusfall || false;
    }
    return false;
  });
  const isUnreachable = computed(
    () => isUnreachableAfterPrognosedExit.value || isUnreachableBeforeExit.value || isNextDepartureCancelled.value,
  );

  watch(
    [journeyLegs, currentTime],
    ([legs, time], [prevLegs]) => {
      const currentSeconds = getSeconds(time);
      if (
        currentSeconds >= 58 ||
        currentSeconds <= 2 ||
        hasNoRelevantLegs.value ||
        haveLegsChanged(legs, prevLegs) ||
        areLegsInvalid(
          currentTime.value,
          isCurrentlyOnTrain.value,
          lastDeparture.value,
          nextArrival.value,
          nextDeparture.value,
        )
      ) {
        // Suche letzte Abfahrt
        const possibleLastDeparture = legs
          .slice()
          .reverse()
          .findIndex((leg) => {
            const departure = leg.abfahrt.fahrtereignis.getZeit();
            return isAfter(time, departure);
          });
        if (possibleLastDeparture !== -1) {
          lastDepartureIndex.value = legs.length - 1 - possibleLastDeparture;
        } else {
          lastDepartureIndex.value = -1;
        }

        // Suche nächste Ankunft
        nextArrivalIndex.value = legs.findIndex((leg) => {
          const arrival = leg.ankunft.fahrtereignis.getZeit();
          return isBefore(time, arrival);
        });

        // Suche nächste Abfahrt
        const possibleNextDeparture = legs.findIndex((leg) => {
          const departure = leg.abfahrt.fahrtereignis.getZeit();
          return isBefore(time, departure);
        });
        const nextDepartureTime =
          (possibleNextDeparture !== -1 && journeyLegs.value[possibleNextDeparture].abfahrt.fahrtereignis.getZeit()) ||
          currentTime.value;
        const nextArrivalTime =
          nextArrivalIndex.value !== -1
            ? journeyLegs.value[nextArrivalIndex.value].ankunft.fahrtereignis.getZeit()
            : currentTime.value;
        if (
          (isCurrentlyOnTrain.value &&
            differenceInMinutes(nextDepartureTime, nextArrivalTime) <= NEXT_DEPARTURE_RANGE_IN_MINUTES) ||
          (!isCurrentlyOnTrain.value &&
            differenceInMinutes(nextDepartureTime, currentTime.value) <= NEXT_DEPARTURE_RANGE_IN_MINUTES)
        ) {
          nextDepartureIndex.value = possibleNextDeparture;
        } else {
          nextDepartureIndex.value = -1;
        }

        if (isCurrentlyOnTrain.value || hasNextDeparture.value) {
          // Suche aktuellen Reiseabschnitt
          currentLegGroupIndex.value = Math.max(
            0,
            journeyLegGroups.value.findIndex(
              (group) => group.startIndex <= nextArrivalIndex.value && nextArrivalIndex.value <= group.endeIndex,
            ),
          );
        } else {
          currentLegGroupIndex.value = journeyLegGroups.value.length - 1;
        }
      }
    },
    {
      immediate: true,
    },
  );

  return {
    journeyLegGroups,
    currentLegGroupIndex,
    lastDeparture,
    nextArrival,
    nextDeparture,
    hasNoRelevantLegs,
    hasNoLegs,
    hasNextDeparture,
    isCurrentlyOnTrain,
    isRefreshingJourneys,
    isUnreachable,
  };
}
