import { type FahrtereignisDto } from '@/model/dto/FahrtereignisDto';
import { Fahrtereignis } from '@/model/fahrtereignis';

export function mapDtoToFahrtereignis(fahrtereignis: FahrtereignisDto): Fahrtereignis {
  return new Fahrtereignis(
    new Date(fahrtereignis.sollZeit),
    fahrtereignis.progZeit !== null ? new Date(fahrtereignis.progZeit) : null,
    fahrtereignis.sollGleis,
    fahrtereignis.progGleis,
    fahrtereignis.isAusfall,
    fahrtereignis.isZusaetzlich,
    fahrtereignis.allFahrtMerkmal,
  );
}

export function mapDtoToNullableFahrtereignis(fahrtereignis: FahrtereignisDto | null): Fahrtereignis | null {
  if (fahrtereignis === null) {
    return null;
  }
  return mapDtoToFahrtereignis(fahrtereignis);
}
