<template>
  <ion-button v-if="isUpdateAvailable" @click="serviceWorker.updateApp">
    <ion-icon slot="icon-only" color="primary" :icon="circleSmall"></ion-icon>
  </ion-button>
</template>

<script setup lang="ts">
import circleSmall from '@/assets/sbb-icon-library/arrows-circle-small.svg';
import { IonButton, IonIcon } from '@ionic/vue';
import { useServiceWorkerStore } from '@/stores/serviceWorker';
import { storeToRefs } from 'pinia';

const serviceWorker = useServiceWorkerStore();
const { isUpdateAvailable } = storeToRefs(serviceWorker);
</script>

<style scoped lang="scss"></style>
