import { type Duration } from 'date-fns';

export abstract class Verzoegerung {
  readonly hasPrognose: boolean;
  readonly wert: Duration | null;

  protected constructor(hasPrognose: boolean, wert: Duration | null) {
    this.hasPrognose = hasPrognose;
    this.wert = wert;
  }

  abstract format(): string;
  abstract isUnderFiveMinutes(): boolean;
}

export class VerzoegerungOhnePrognose extends Verzoegerung {
  declare readonly wert: null;

  constructor() {
    super(false, null);
  }

  format(): string {
    return '';
  }

  isUnderFiveMinutes(): boolean {
    return false;
  }
}

export class VerzoegerungMitPrognose extends Verzoegerung {
  declare readonly wert: Duration;
  readonly total: number;

  constructor(wert: Duration) {
    super(true, wert);
    const hours = (this.wert.hours || 0) * 60;
    const minutes = this.wert.minutes || 0;
    this.total = hours + minutes;
  }

  format(): string {
    if (this.total >= 0) {
      return `+${this.total}`;
    }
    return `–${Math.abs(this.total)}`;
  }

  isUnderFiveMinutes(): boolean {
    return this.total <= 5;
  }
}
