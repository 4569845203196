import { type AbschnittFahrtereignis } from '@/model/abschnitt';
import { type AbschnittFahrtereignisDto } from '@/model/dto/AbschnittFahrtereignisDto';
import { mapFahrtereignisToDto } from '@/mapper/to-store-dto/fahrtereignis';

export function mapAbschnittFahrtereignisToDto(fahrtereignis: AbschnittFahrtereignis): AbschnittFahrtereignisDto {
  return {
    fahrtereignis: mapFahrtereignisToDto(fahrtereignis.fahrtereignis),
    haltestelle: fahrtereignis.haltestelle,
    isUnbestimmtVerspaetet: fahrtereignis.isUnbestimmtVerspaetet,
    wagenreihung: fahrtereignis.wagenreihung,
  };
}
