import { model } from '@zentrains/api';
import { type Reise } from '@/model/reise';
import { mapExternalDauer } from '@/mapper/from-api/helper';
import { mapExternalZeit } from '@/mapper/from-api/zeit';
import { mapExternalAbschnitt } from '@/mapper/from-api/abschnitt';
import { mapExternalIdentifikation } from '@/mapper/from-api/identifikation';

export function mapExternalReise(reise: model.Reise): Reise {
  const identifikation = reise.id;
  if (identifikation === undefined) {
    throw 'Identifikation of Reise is undefined, which is illegal';
  }
  const abfahrt = reise.abfahrt;
  if (abfahrt === undefined) {
    throw 'Abfahrt of Reise is undefined, which is illegal';
  }
  const ankunft = reise.ankunft;
  if (ankunft === undefined) {
    throw 'Ankunft of Reise is undefined, which is illegal';
  }
  return {
    id: mapExternalIdentifikation(identifikation),
    dauer: mapExternalDauer(reise.dauer),
    umstiege: reise.umstiege,
    isAlternative: reise.isAlternative,
    isFahrbar: reise.isFahrbar,
    abfahrt: mapExternalZeit(abfahrt),
    ankunft: mapExternalZeit(ankunft),
    allAbschnitt: reise.allAbschnitt.map(mapExternalAbschnitt),
  };
}
