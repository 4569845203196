import { type FahrtereignisDto } from '@/model/dto/FahrtereignisDto';
import { Fahrtereignis } from '@/model/fahrtereignis';

export function mapFahrtereignisToDto(fahrtereignis: Fahrtereignis): FahrtereignisDto {
  return {
    sollZeit: fahrtereignis.sollZeit.valueOf(),
    sollGleis: fahrtereignis.sollGleis,
    progZeit: fahrtereignis.progZeit !== null ? fahrtereignis.progZeit.valueOf() : null,
    progGleis: fahrtereignis.progGleis,
    isAusfall: fahrtereignis.isAusfall,
    isZusaetzlich: fahrtereignis.isZusaetzlich,
    allFahrtMerkmal: fahrtereignis.allFahrtMerkmal,
  };
}

export function mapFahrtereignisToNullableDto(fahrtereignis: Fahrtereignis | null): FahrtereignisDto | null {
  if (fahrtereignis === null) {
    return null;
  }
  return mapFahrtereignisToDto(fahrtereignis);
}
