import { Halt } from '@/model/halt';
import { type HaltDto } from '@/model/dto/HaltDto';
import { mapFahrtereignisToNullableDto } from '@/mapper/to-store-dto/fahrtereignis';

export function mapHaltToDto(halt: Halt): HaltDto {
  return {
    abfahrt: mapFahrtereignisToNullableDto(halt.abfahrt),
    ankunft: mapFahrtereignisToNullableDto(halt.ankunft),
    haltestelle: halt.haltestelle,
    auslastung: halt.auslastung,
    allMeldung: halt.allMeldung,
  };
}
