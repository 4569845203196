import type { Ref } from 'vue';
import { registerPeriodicSync } from '@/stores/service-worker/updating';
import { UPDATE_CHECK_PERIOD } from '@/stores/service-worker/constants';

export function handleServiceWorkerActivation(
  registrationRef: Ref<ServiceWorkerRegistration | null>,
  urlRef: Ref<string | null>,
  newRegistration: ServiceWorkerRegistration | undefined,
  newUrl: string,
) {
  if (UPDATE_CHECK_PERIOD <= 0) return;
  if (newRegistration?.active?.state === 'activated') {
    registrationRef.value = newRegistration;
    urlRef.value = newUrl;
    registerPeriodicSync(newRegistration, newUrl);
  } else if (newRegistration?.installing) {
    newRegistration.installing.addEventListener('statechange', (e) => {
      const sw = e.target as ServiceWorker;
      if (sw.state === 'activated') {
        registrationRef.value = newRegistration;
        urlRef.value = newUrl;
        registerPeriodicSync(newRegistration, newUrl);
      }
    });
  }
}
