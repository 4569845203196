import { createChannel, createClient } from 'nice-grpc-web';
import { LOCAL_ENDPOINT } from '@/api/common';
import { api, notification } from '@zentrains/api';

const localChannel = createChannel(LOCAL_ENDPOINT);
export const localApi: api.ZenTrainsClient = createClient(api.ZenTrainsDefinition, localChannel);
export const localNotificationApi: notification.NotificationClient = createClient(
  notification.NotificationDefinition,
  localChannel,
);
