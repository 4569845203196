<template>
  <IonApp>
    <IonSplitPane content-id="main-content">
      <ion-menu content-id="main-content" type="overlay">
        <ion-content>
          <ion-list id="main-list">
            <ion-list-header>ZenTrains</ion-list-header>
            <ion-note>by Zenti ({{ version }} &ndash; {{ environment }}-{{ buildTarget }})</ion-note>

            <ion-menu-toggle :auto-hide="false">
              <ion-item
                router-link="/"
                lines="none"
                :detail="false"
                class="hydrated"
                :class="{ selected: route.path === '/' }"
              >
                <ion-icon slot="start" :icon="houseSmall" />
                <ion-label>Home</ion-label>
              </ion-item>
              <ion-item
                router-link="/myRoutes"
                lines="none"
                :detail="false"
                class="hydrated"
                :class="{ selected: route.path === '/myRoutes' }"
              >
                <ion-icon slot="start" :icon="ticketRouteSmall" />
                <ion-label>Meine Reisen</ion-label>
              </ion-item>
              <ion-item
                router-link="/myPickups"
                lines="none"
                :detail="false"
                class="hydrated"
                :class="{ selected: route.path === '/myPickups' }"
              >
                <ion-icon slot="start" :icon="trainStationSmall" />
                <ion-label>Abholen (Beta)</ion-label>
              </ion-item>
              <ion-item
                router-link="/settings"
                lines="none"
                :detail="false"
                class="hydrated"
                :class="{ selected: route.path === '/settings' }"
              >
                <ion-icon slot="start" :icon="controlsSmall" />
                <ion-label>Einstellungen</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
          <ion-list id="info-list">
            <ion-menu-toggle :auto-hide="false">
              <ion-item
                router-link="/about"
                lines="none"
                :detail="false"
                class="hydrated"
                :class="{ selected: route.path === '/about' }"
              >
                <ion-icon slot="start" :icon="information" />
                <ion-label>Über die App</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
          <ion-list id="actions-list">
            <ion-menu-toggle :auto-hide="false">
              <sw-search-for-update-button v-if="isServiceWorkerEnabled" />
              <ion-item v-if="isCheckinActive" :detail="false" class="hydrated" lines="none" @click="resetCheckin">
                <ion-icon slot="start" :icon="refreshOutline" />
                <ion-label>Checkin zurücksetzen</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
          <ion-list v-if="isBackendSelectionUnlocked" id="dev-list">
            <ion-menu-toggle :auto-hide="false">
              <ion-item>
                <ion-select
                  label="Backend"
                  placeholder="backend"
                  :value="backendStore.selectedBackend"
                  @ion-change="backendStore.selectedBackend = $event.detail.value"
                >
                  <ion-select-option v-for="item in allBackend" :key="`backend-${item.value}`" :value="item.value">
                    {{ item.title }}
                  </ion-select-option>
                </ion-select>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
        </ion-content>
      </ion-menu>
      <ion-page id="main-content">
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-menu-button color="primary" />
            </ion-buttons>
            <omni-search-bar v-if="shouldShowOmniSearchBar" />
            <ion-title v-else>{{ route.name }}</ion-title>
            <ion-buttons slot="end">
              <sw-update-button v-if="isServiceWorkerEnabled" />
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-router-outlet class="zen-router-outlet" />
        <journey-overlay />
        <pickup-overlay />
      </ion-page>
    </IonSplitPane>
  </IonApp>
</template>

<script lang="ts">
import {
  IonApp,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuButton,
  IonMenuToggle,
  IonNote,
  IonPage,
  IonRouterOutlet,
  IonSelect,
  IonSelectOption,
  IonSplitPane,
  IonTitle,
  IonToolbar,
  useBackButton,
} from '@ionic/vue';
import { defineComponent, onBeforeUnmount, ref } from 'vue';
import { useRoute } from 'vue-router';
import { information, refreshOutline } from 'ionicons/icons';
import OmniSearchBar from '@/components/navbar/OmniSearchBar.vue';
import useOmniSearchBar from '@/composables/useOmniSearchBar';
import houseSmall from '@/assets/sbb-icon-library/house-small.svg';
import ticketRouteSmall from '@/assets/sbb-icon-library/ticket-route-small.svg';
import trainStationSmall from '@/assets/sbb-icon-library/train-station-small.svg';
import controlsSmall from '@/assets/sbb-icon-library/controls-small.svg';
import { INTERVAL_REFRESH_JOURNEYS } from '@/constants';
import useNativePushNotifications from '@/composables/native/useNativePushNotifications';
import { App } from '@capacitor/app';
import JourneyOverlay from '@/components/journey-assistance/JourneyOverlay.vue';
import useSettings from '@/composables/useSettings';
import useResetCheckin from '@/composables/useResetCheckin';
import useDeepLinks from '@/composables/useDeepLinks';
import useSharing from '@/composables/useSharing';
import useNativePersistentState from '@/composables/native/useNativePersistentState';
import PickupOverlay from '@/components/pickup/PickupOverlay.vue';
import useReiseBegleitung from '@/composables/reise-begleitung/useReiseBegleitung';
import { allBackend, useBackendStore } from '@/stores/backend';
import { ROUTE } from '@/router/names';
import SwSearchForUpdateButton from '@/components/service-worker/SwSearchForUpdateButton.vue';
import SwUpdateButton from '@/components/service-worker/SwUpdateButton.vue';
import { useEnvStore } from '@/stores/env';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'App',
  components: {
    SwUpdateButton,
    SwSearchForUpdateButton,
    PickupOverlay,
    JourneyOverlay,
    IonApp,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuButton,
    IonMenuToggle,
    IonNote,
    IonPage,
    IonRouterOutlet,
    IonSelect,
    IonSelectOption,
    IonSplitPane,
    IonTitle,
    IonToolbar,
    OmniSearchBar,
  },
  setup() {
    const selectedIndex = ref(0);

    const route = useRoute();
    const { shouldShowOmniSearchBar } = useOmniSearchBar();
    const { isCheckinActive, isBackendSelectionUnlocked } = useSettings();
    const backendStore = useBackendStore();
    useNativePushNotifications();

    const { restoreRoute, setupRoutePersistence } = useNativePersistentState();
    restoreRoute();
    setupRoutePersistence();

    const { reiseBegleitungStore } = useReiseBegleitung();

    const { buildTarget, environment, isServiceWorkerEnabled, version } = storeToRefs(useEnvStore());

    reiseBegleitungStore.refreshAllReise();

    useDeepLinks();
    useBackButton(-1, () => {
      if (route.name === ROUTE.HOME) {
        App.exitApp();
      }
    });
    useSharing().determineSharingCapability();

    const intervalRefreshJourney = ref(
      window.setInterval(reiseBegleitungStore.refreshAllReise, INTERVAL_REFRESH_JOURNEYS),
    );
    onBeforeUnmount(() => window.clearInterval(intervalRefreshJourney.value));

    return {
      selectedIndex,
      environment,
      isServiceWorkerEnabled,
      version,
      isSelected: (url: string) => (url === route.path ? 'selected' : ''),
      shouldShowOmniSearchBar,
      route,
      buildTarget,
      isCheckinActive,
      ...useResetCheckin(),
      backendStore,
      allBackend,
      isBackendSelectionUnlocked,
      ticketRouteSmall,
      trainStationSmall,
      houseSmall,
      controlsSmall,
      information,
      refreshOutline,
    };
  },
});
</script>

<style scoped lang="scss">
.zen-router-outlet {
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1 1 0;
}

ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md {
  ion-list#main-list,
  ion-list#info-list {
    border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
  }
}

ion-menu.md ion-list#main-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
}

ion-menu.md ion-item ion-icon {
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}
</style>
