import { Zeit } from '@/model/zeit';
import { VerzoegerungMitPrognose, VerzoegerungOhnePrognose } from '@/model/verzoegerung';
import { FahrtMerkmal } from '@/model/fahrt';

export class Fahrtereignis extends Zeit {
  declare readonly sollZeit: Date;
  declare readonly progZeit: Date | null;
  readonly sollGleis: string;
  readonly progGleis: string | null;
  readonly isAusfall: boolean;
  readonly isZusaetzlich: boolean;
  readonly allFahrtMerkmal: FahrtMerkmal[];

  constructor(
    sollZeit: Date,
    progZeit: Date | null,
    sollGleis: string,
    progGleis: string | null,
    isAusfall: boolean,
    isZusaetzlich: boolean,
    allFahrtMerkmal: FahrtMerkmal[],
  ) {
    super(sollZeit, progZeit);
    this.sollGleis = sollGleis;
    this.progGleis = progGleis;
    this.isAusfall = isAusfall;
    this.isZusaetzlich = isZusaetzlich;
    this.allFahrtMerkmal = allFahrtMerkmal;
  }

  getVerzoegerung(): VerzoegerungOhnePrognose | VerzoegerungMitPrognose {
    return super.getVerzoegerung();
  }
}
