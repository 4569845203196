import { ActivePushMethod, useNotificationsStore } from '@/stores/notifications';
import { useSettingsStore } from '@/stores/settings';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';
import { Toast } from '@capacitor/toast';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from '@/notifications/firebaseConfig';
import { setupMessageReception } from '@/stores/service-worker/push-notification-handler';
import { captureException } from '@sentry/vue';
import { VAPID_KEY } from '@/stores/service-worker/constants';

async function activatePushNotifications(serviceWorkerRegistration: ServiceWorkerRegistration) {
  const settingsStore = useSettingsStore();
  if (!(await isSupported())) {
    await Toast.show({
      text: 'Dieser Browser unterstützt leider keine Push-Benachrichtigungen.',
      duration: 'long',
    });
    settingsStore.pushNotificationsActive = false;
    return;
  }

  try {
    const firebaseApp = initializeApp(firebaseConfig, {
      automaticDataCollectionEnabled: false,
    });
    const messaging = getMessaging(firebaseApp);

    const notificationStore = useNotificationsStore();
    if (notificationStore.activePushMethod !== ActivePushMethod.NONE) {
      console.log('Nutze bestehendes Token.', notificationStore.pushToken);
      await setupMessageReception(messaging, serviceWorkerRegistration);
      return;
    }

    const currentToken = await getToken(messaging, {
      vapidKey: VAPID_KEY,
      serviceWorkerRegistration,
    });

    if (currentToken) {
      console.log('Nutze neues Token.', currentToken);
      const notificationStore = useNotificationsStore();
      notificationStore.activatePush(currentToken, ActivePushMethod.SERVICE_WORKER);
      await setupMessageReception(messaging, serviceWorkerRegistration);
    } else {
      console.log('Kein Push-Token verfügbar, kein Service Worker zur Rechteanforderung verfügbar.');
    }
  } catch (e) {
    console.error('Fehler bei der Anforderung eines Push-Tokens. ', e);
    await Toast.show({
      text: 'Fehler bei der Anforderung eines Push-Tokens',
      duration: 'long',
    });
    captureException(e);
    settingsStore.pushNotificationsActive = false;
  }
}

export async function handleServiceWorkerAndSettingsChange(
  serviceWorkerRegistration: ServiceWorkerRegistration | null,
  pushNotificationsActive: boolean,
) {
  if (serviceWorkerRegistration !== null) {
    const notificationStore = useNotificationsStore();
    if (pushNotificationsActive) {
      await activatePushNotifications(serviceWorkerRegistration);
    } else if (notificationStore.activePushMethod !== ActivePushMethod.NONE) {
      await notificationStore.deactivatePush();
    }
  }
}
