import { defineStore } from 'pinia';
import { addHours, differenceInMinutes, format, isBefore } from 'date-fns';
import { DATE_FORMAT, NEXT_DEPARTURE_RANGE_IN_MINUTES } from '@/constants';
import { type Reise } from '@/model/reise';
import { groupBy } from 'lodash-es';
import { type Abschnitt, AbschnittTyp } from '@/model/abschnitt';
import { captureException } from '@sentry/vue';
import { useNotificationsStore } from '@/stores/notifications';
import { mapDtoToReise, mapDtoToReiseIdentifikation } from '@/mapper/from-store-dto/reise';
import { mapReiseIdentifikationToDto, mapReiseToDto } from '@/mapper/to-store-dto/reise';
import { type AbschnittGruppe } from '@/model/abschnittGruppe';
import { useBackendStore } from '@/stores/backend';
import { Identifikation } from '@/model/identifikation';
import type { ReiseDto, ReiseIdentifikationDto } from '@/model/dto/ReiseDto';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function reiseByProgAbfahrt(a: Reise, b: Reise) {
  return a.abfahrt.getZeit().getTime() - b.abfahrt.getZeit().getTime();
}
function reiseBySollAbfahrt(a: Reise, b: Reise) {
  return a.abfahrt.sollZeit.getTime() - b.abfahrt.sollZeit.getTime();
}
function abschnittByProgAbfahrt(a: Abschnitt, b: Abschnitt) {
  return a.abfahrt.fahrtereignis.getZeit().getTime() - b.abfahrt.fahrtereignis.getZeit().getTime();
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function abschnittBySollAbfahrt(a: Abschnitt, b: Abschnitt) {
  return a.abfahrt.fahrtereignis.sollZeit.getTime() - b.abfahrt.fahrtereignis.sollZeit.getTime();
}

export interface ReiseIdToReiseDto {
  [reiseId: string]: ReiseDto;
}

export interface ReiseBegleitungState {
  allReiseIdDto: ReiseIdentifikationDto[];
  reiseIdToReiseDto: ReiseIdToReiseDto;
  updating: boolean;
}

export const useReiseBegleitungStore = defineStore('reiseBegleitung', {
  state: (): ReiseBegleitungState => ({
    allReiseIdDto: [],
    reiseIdToReiseDto: {},
    updating: false,
  }),
  persist: true,
  getters: {
    allReiseId(): Identifikation[] {
      return this.allReiseIdDto.map(mapDtoToReiseIdentifikation);
    },
    allReise(): Reise[] {
      return Object.values(this.reiseIdToReiseDto).map(mapDtoToReise);
    },
    reiseIdToReiseMap(): Map<string, Reise> {
      return new Map<string, Reise>(this.allReise.map((r) => [r.id.toString(), r]));
    },
    allAbschnitt(): Abschnitt[] {
      return this.allReise
        .flatMap((r) => r.allAbschnitt)
        .filter((a) => a.typ === AbschnittTyp.Fahrt)
        .sort(abschnittByProgAbfahrt);
    },
    allReiseByDate(): Map<string, Reise[]> {
      const allReise = this.allReise.sort(reiseBySollAbfahrt);

      const grouped = groupBy(allReise, (reise) => format(reise.abfahrt.sollZeit, DATE_FORMAT));
      return new Map<string, Reise[]>(Object.entries(grouped));
    },
    allAbschnittGruppe(): AbschnittGruppe[] {
      const result: AbschnittGruppe[] = [];

      const allAbschnitt: Abschnitt[] = this.allAbschnitt;
      let newGroup: AbschnittGruppe = {
        startIndex: 0,
        endeIndex: allAbschnitt.length - 1,
      };
      if (allAbschnitt.length > 1) {
        allAbschnitt.forEach((leg, index) => {
          const nextLeg = allAbschnitt[index + 1];
          if (
            allAbschnitt.length > index + 1 &&
            nextLeg !== undefined &&
            differenceInMinutes(nextLeg.abfahrt.fahrtereignis.getZeit(), leg.ankunft.fahrtereignis.getZeit()) >
              NEXT_DEPARTURE_RANGE_IN_MINUTES
          ) {
            newGroup.endeIndex = index;
            result.push(newGroup);
            newGroup = {
              startIndex: index + 1,
              endeIndex: allAbschnitt.length - 1,
            };
          }
        });
      }
      result.push(newGroup);
      return result;
    },
  },
  actions: {
    clearAllReise(): void {
      this.allReiseIdDto = [];
      this.reiseIdToReiseDto = {};
    },
    async deleteReise(reiseId: Identifikation, updateNotificationServer: boolean): Promise<void> {
      delete this.reiseIdToReiseDto[reiseId.toString()];
      const index = this.allReiseId.findIndex((id) => id.toString() === reiseId.toString());
      if (index !== -1) {
        this.allReiseIdDto.splice(index, 1);
      }
      if (updateNotificationServer) {
        await useNotificationsStore().sendAllReiseToNotificationServer();
      }
    },
    async refreshReise(reset: boolean, ...allReiseId: Identifikation[]): Promise<void> {
      try {
        this.updating = true;
        const backend = useBackendStore();
        const allReise = await backend.refreshReise(allReiseId);
        if (reset && allReise.length === this.allReiseId.length) {
          const now = Date.now();
          const allRelevantReise = allReise.filter((reise) => isBefore(now, addHours(reise.ankunft.getZeit(), 6)));
          this.allReiseIdDto = allRelevantReise.map((reise) => mapReiseIdentifikationToDto(reise.id));
          this.reiseIdToReiseDto = allRelevantReise.reduce((obj: ReiseIdToReiseDto, reise: Reise) => {
            obj[reise.id.toString()] = mapReiseToDto(reise);
            return obj;
          }, {} as ReiseIdToReiseDto);
        } else {
          allReise.forEach((reise) => {
            this.reiseIdToReiseDto[reise.id.toString()] = mapReiseToDto(reise);
          });
        }
        await useNotificationsStore().sendAllReiseToNotificationServer();
      } catch (e) {
        captureException(e);
      } finally {
        this.updating = false;
      }
    },
    async refreshAllReise(): Promise<void> {
      if (this.allReiseId.length > 0) {
        await this.refreshReise(true, ...this.allReiseId);
      }
    },
    async replaceReise(oldReiseId: Identifikation, newReiseId: Identifikation) {
      await this.deleteReise(oldReiseId, false);
      await this.saveReise(newReiseId);
    },
    async saveReise(...reiseId: Identifikation[]): Promise<void> {
      this.allReiseIdDto.push(...reiseId.map(mapReiseIdentifikationToDto));
      await this.refreshReise(false, ...reiseId);
    },
  },
});
