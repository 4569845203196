import { type Reise } from '@/model/reise';
import { type ReiseDto, type ReiseIdentifikationDto } from '@/model/dto/ReiseDto';
import { mapDtoToZeit } from '@/mapper/from-store-dto/zeit';
import { mapDtoToAbschnitt } from '@/mapper/from-store-dto/abschnitt';
import { Identifikation } from '@/model/identifikation';

export function mapDtoToReise(reiseDto: ReiseDto): Reise {
  return {
    id: mapDtoToReiseIdentifikation(reiseDto.id),
    abfahrt: mapDtoToZeit(reiseDto.abfahrt),
    ankunft: mapDtoToZeit(reiseDto.ankunft),
    dauer: reiseDto.dauer,
    isFahrbar: reiseDto.isFahrbar,
    isAlternative: reiseDto.isAlternative,
    umstiege: reiseDto.umstiege,
    allAbschnitt: reiseDto.allAbschnitt.map(mapDtoToAbschnitt),
  };
}

export function mapDtoToReiseIdentifikation(id: ReiseIdentifikationDto): Identifikation {
  return new Identifikation(id.reiseQuelle, id.reiseId);
}
