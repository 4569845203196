import { model } from '@zentrains/api';

import { Quelle } from '@/model/identifikation';

export function mapToExternalQuelle(quelle: Quelle): model.Quelle {
  switch (quelle) {
    case Quelle.DB_BUSINESS_NAVIGATOR:
      return model.Quelle.QUELLE_DB_BUSINESS_NAVIGATOR;
    case Quelle.DB_NAVIGATOR:
      return model.Quelle.QUELLE_DB_NAVIGATOR;
    case Quelle.OEBB:
      return model.Quelle.QUELLE_OEBB;
    case Quelle.SBB:
      return model.Quelle.QUELLE_SBB;
    case Quelle.IRIS:
      return model.Quelle.QUELLE_IRIS;
    case Quelle.MUY_VLAK:
      return model.Quelle.QUELLE_MUY_VLAK;
    case Quelle.NS:
      return model.Quelle.QUELLE_NS;
    case Quelle.PKP:
      return model.Quelle.QUELLE_PKP;
    case Quelle.SNCF_CONNECT:
      return model.Quelle.QUELLE_SNCF_CONNECT;
    case Quelle.UNBEKANNT:
      return model.Quelle.UNRECOGNIZED;
  }
}
