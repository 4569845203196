import { defineStore } from 'pinia';
import { useRegisterSW } from 'virtual:pwa-register/vue';
import { computed, onBeforeMount, type Ref, ref, watch } from 'vue';
import { Toast } from '@capacitor/toast';
import { loadingController } from '@ionic/vue';
import VERSION from '@/env/version';
import { useVersionStore } from '@/stores/version';
import { isDeviceOffline } from '@/stores/common/device-offline';
import { doUpdateCheck } from '@/stores/service-worker/updating';
import { handleServiceWorkerActivation } from '@/stores/service-worker/activation';
import { useSettingsStore } from '@/stores/settings';
import { handleServiceWorkerAndSettingsChange } from '@/stores/service-worker/push-notification-activation';

export const useServiceWorkerStore = defineStore('serviceWorker', () => {
  const serviceWorkerRegistration: Ref<ServiceWorkerRegistration | null> = ref(null);
  const serviceWorkerUrl: Ref<string | null> = ref(null);
  const isServiceWorkerActive = computed(() => serviceWorkerRegistration.value !== null);

  const {
    needRefresh: isUpdateAvailable,
    offlineReady: isOfflineReady,
    updateServiceWorker,
  } = useRegisterSW({
    immediate: true,
    async onNeedRefresh() {
      await Toast.show({
        text: 'Es ist ein Update verfügbar.\nTippe zum Aktualisieren oben rechts auf das Update-Symbol.',
        duration: 'long',
      });
    },
    onRegisteredSW(swUrl, r) {
      handleServiceWorkerActivation(serviceWorkerRegistration, serviceWorkerUrl, r, swUrl);
    },
  });

  /// region Actions

  async function checkForUpdates() {
    if (serviceWorkerRegistration.value === null || serviceWorkerUrl.value === null) {
      // TODO: Fehler anzeigen?
      return;
    }
    if (isDeviceOffline()) {
      await Toast.show({
        text: 'Gerät ist offline, bitte versuche es später erneut.',
        duration: 'short',
      });
      return;
    }
    await Promise.all([
      Toast.show({
        text: 'Prüfe auf Updates...',
        duration: 'short',
      }),
      doUpdateCheck(serviceWorkerRegistration.value, serviceWorkerUrl.value),
    ]);
  }

  async function updateApp() {
    if (!isServiceWorkerActive.value) {
      return;
    }
    const loading = await loadingController.create({
      cssClass: 'updating-app',
      message: 'ZenTrains wird aktualisiert,\nbitte warten...',
      duration: 5000,
    });
    serviceWorkerRegistration.value?.addEventListener('controllerchange', () => {
      window.location.reload();
    });
    await Promise.all([updateServiceWorker(), loading.present()]);
  }

  /// endregion Actions

  /// region Lifecycle Management

  const settingsStore = useSettingsStore();
  const pushNotificationsActive = computed((): boolean => settingsStore.pushNotificationsActive);
  watch(
    serviceWorkerRegistration,
    async (val) => await handleServiceWorkerAndSettingsChange(val, pushNotificationsActive.value),
  );
  watch(
    pushNotificationsActive,
    async (val) => await handleServiceWorkerAndSettingsChange(serviceWorkerRegistration.value, val),
  );

  onBeforeMount(async () => {
    const versionStore = useVersionStore();
    if (versionStore.lastVersion !== VERSION) {
      versionStore.lastVersion = VERSION;
      await Toast.show({
        text: 'ZenTrains wurde erfolgreich aktualisiert.',
        duration: 'long',
      });
    }
  });

  /// endregion Lifecycle Management

  return {
    serviceWorkerRegistration,
    serviceWorkerUrl,
    isServiceWorkerActive,
    isOfflineReady,
    isUpdateAvailable,
    checkForUpdates,
    updateApp,
  };
});
